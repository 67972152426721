import google from 'assets/img/nav-icons/google.png';
import spotify from 'assets/img/nav-icons/spotify.png';
import trello from 'assets/img/nav-icons/trello.png';
import account from 'assets/img/team/avatar.png';
import linkedin from 'assets/img/nav-icons/linkedin.png';
import twitter from 'assets/img/nav-icons/twitter.png';
import facebook from 'assets/img/nav-icons/facebook.png';
import instagram from 'assets/img/nav-icons/instagram.png';

export const quickLinks = [
  {
    avatar: account,
    title: 'Conta',
    link: `/user/profile`
  },
  { hr: true },
  {
    avatarText: 'C',
    title: 'Carregamento',
    link: `/kpis/carregamento`,
    contentClass: 'bg-soft-primary text-white'
  },
  {
    avatarText: 'D',
    title: 'Descarga',
    link: `/kpis/descarga`,
    contentClass: 'bg-soft-primary text-white'
  },
  {
    avatarText: 'A',
    title: 'Adicionais',
    link: `/kpis/adicionais`,
    contentClass: 'bg-soft-primary text-white'
  }
];
