import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { getColor } from 'helpers/utils';
import * as echarts from 'echarts/core';
import { PieChart } from 'echarts/charts';

import {
  GridComponent,
  TooltipComponent,
  TitleComponent
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import BasicECharts from 'components/common/BasicEChart';
import { Spinner } from 'reactstrap';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  PieChart,
  CanvasRenderer
]);

const getOptions = (data, radius) => ({
  color: [getColor(data[0]?.color), getColor(data[1]?.color)],

  tooltip: {
    padding: [7, 10],
    transitionDuration: 0,
    backgroundColor: getColor('100'),
    borderColor: getColor('300'),
    textStyle: { color: getColor('dark') },
    borderWidth: 1,
    formatter: params =>
      `<strong>${params.data.name}:</strong> ${params.data.value}`
  },
  series: [
    {
      name: '',
      type: 'pie',
      radius,
      avoidLabelOverlap: false,
      emphasis: {
        scale: false
      },
      itemStyle: {
        borderWidth: 2,
        borderColor: getColor('card-bg')
      },
      label: {
        show: true,
        position: 'center',
        formatter: '{a}',
        fontSize: 23,
        color: getColor('dark')
      },
      data
    }
  ]
});

const TipoMotoristasItem = ({ item, index, total }) => {
  const { name, color, value } = item;

  const percentage = ((parseInt(value) * 100) / total).toFixed(0);
  return (
    <Flex
      alignItems="center"
      justifyContent="between"
      className={`fw-semi-bold fs--2 ${index == 0 && 'mt-3'}`}
    >
      <p className="mb-1">
        <FontAwesomeIcon
          icon="circle"
          className={`me-2 text-${color || 'text-info'}`}
        />
        {name} {'  '}
        {percentage}%
      </p>
    </Flex>
  );
};

const InglishTipoMotoristas = ({ data, radius }) => {
  const total = data.reduce((acc, val) => parseInt(val.value) + acc, 0);

  return (
    <>
      <Row className="justify-content-between">
        <h6 className="mt-1">
          Driver Type
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip>Percentage of Drivers for the Period's Trips</Tooltip>
            }
          >
            <span>
              <FontAwesomeIcon
                icon={['far', 'question-circle']}
                transform="shrink-1"
                className="ms-1 text-400"
                id="weeklySalesTooltip"
              />
            </span>
          </OverlayTrigger>
        </h6>
      </Row>
      <div>
        <BasicECharts
          echarts={echarts}
          options={getOptions(data, radius)}
          style={{ margin: '0 auto', maxWidth: '70%' }}
        />
      </div>
      <div className="text-center">
        {data.map((item, index) => (
          <TipoMotoristasItem
            item={item}
            index={index}
            key={index}
            total={total}
          />
        ))}
      </div>
    </>
  );
};

TipoMotoristasItem.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.any,
    color: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  }),
  index: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired
};

InglishTipoMotoristas.propTypes = {
  data: PropTypes.array.isRequired,
  radius: PropTypes.array.isRequired
};

export default InglishTipoMotoristas;
