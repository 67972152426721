import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Modal } from 'react-bootstrap';
import AppContext from 'context/Context';
import DatePicker from 'react-datepicker';
import axios from 'axios';
import { baseUrl } from '../../../config';

const EditScheduleModal = ({
  setIsOpenEditScheduleModal,
  isOpenEditScheduleModal,
  id
}) => {
  const {
    config: { isDark }
  } = useContext(AppContext);

  const [title, setTitle] = useState('');
  const [inicio, setInicio] = useState('');
  const [fim, setFim] = useState('');
  const [url, setUrl] = useState('');
  const [description, setDescription] = useState('');
  const [label, setLabel] = useState(0);

  const getData = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${sessionStorage.token}`
      }
    };

    axios
      .get(`${baseUrl}/desk/calendario/${id}`, config)
      .then(response => {
        setTitle(response.data.titulo);
        setInicio(response.data.data_inicio);
        setFim(response.data.data_fim);
        setUrl(response.data.url_video);
        setDescription(response.data.descricao);
        setLabel(response.data.label);
      })
      .catch(error => {
        console.log(`Erro ${error}`);
      });
  };

  const saveData = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${sessionStorage.token}`
      }
    };

    axios
      .put(
        `${baseUrl}/desk/calendario/${id}`,
        {
          id_user: 1,
          id_cliente: 1,
          label: label,
          url_video: url,
          local: '',
          titulo: title,
          descricao: description,
          data_inicio: inicio,
          data_fim: fim
        },
        config
      )
      .then(response => {
        return response.status === 200 && setIsOpenEditScheduleModal(false);
        // eslint-disable-next-line no-unreachable
        window.unload();
      })
      .catch(error => {
        console.log(`Erro ${error}`);
      });
  };

  const handleClose = () => {
    setIsOpenEditScheduleModal(!isOpenEditScheduleModal);
  };

  useEffect(() => {
    getData();
  }, [id]);

  return (
    <Modal
      show={isOpenEditScheduleModal}
      onHide={handleClose}
      contentClassName="border"
    >
      <Form onSubmit={saveData}>
        <Modal.Header
          closeButton
          closeVariant={isDark ? 'white' : undefined}
          className="bg-light px-card border-bottom-0"
        >
          <Modal.Title as="h5">Editar Evento</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-card">
          <Form.Group className="mb-3" controlId="titleInput">
            <Form.Label className="fs-0">Titulo</Form.Label>
            <Form.Control
              type="text"
              name="title"
              defaultValue={title}
              onChange={e => {
                setTitle(e.target.value);
              }}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="startDate">
            <Form.Label className="fs-0">Data inicial</Form.Label>
            <DatePicker
              onChange={date => {
                setInicio(date);
              }}
              className="form-control"
              placeholderText="MM-DD-YYYY H:M"
              dateFormat="MM-dd-yyyy h:mm aa"
              showTimeSelect
              defaultValue={inicio}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="endDate">
            <Form.Label className="fs-0">Data final</Form.Label>
            <DatePicker
              onChange={date => {
                setFim(date);
              }}
              className="form-control"
              placeholderText="MM-DD-YYYY H:M"
              dateFormat="MM-dd-yyyy h:mm aa"
              showTimeSelect
              defaultValue={fim}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="fs-0">URL</Form.Label>
            <Form.Control
              type="url"
              rows={3}
              name="url"
              onChange={e => {
                setUrl(e.target.value);
              }}
              defaultValue={url}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="description">
            <Form.Label className="fs-0">Descrição</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="description"
              onChange={e => {
                setDescription(e.target.value);
              }}
              defaultValue={description}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="label">
            <Form.Label className="fs-0">Label</Form.Label>
            <Form.Select
              name="className"
              onChange={e => {
                setLabel(e.target.value);
              }}
              defaultValue={label}
            >
              <option>Nenhuma</option>
              <option value="1">Informativo</option>
              <option value="2">danger</option>
              <option value="3">warning</option>
              <option value="4">sucess</option>
            </Form.Select>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="bg-light px-card border-top-0">
          <Button
            variant="primary"
            type="submit"
            onClick={() => {
              saveData();
            }}
            className="px-4 mx-0"
          >
            Salvar
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

EditScheduleModal.propTypes = {
  setIsOpenEditScheduleModal: PropTypes.func.isRequired,
  isOpenEditScheduleModal: PropTypes.bool.isRequired,
  id: PropTypes.number
};

export default EditScheduleModal;
