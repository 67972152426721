export default function CheckRoles({ children, roles }) {
  const user = JSON.parse(sessionStorage.getItem('user'));

  const userRole = user?.role;

  if (!roles.includes(userRole)) {
    // eslint-disable-next-line react/react-in-jsx-scope
    return <></>;
  } else {
    // eslint-disable-next-line react/react-in-jsx-scope
    return <>{children}</>;
  }
}
