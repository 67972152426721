import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import bg1 from 'assets/img/generic/bg-1.jpg';
import dashboardDark from 'assets/img/generic/dashboard-alt-dark.png';
import dashboard from 'assets/img/generic/dashboard-alt.png';
import Section from 'components/common/Section';
import AppContext from 'context/Context';
import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import TypeAnimation from 'react-type-animation';

const Hero = () => {
  const {
    config: { isDark, isRTL }
  } = useContext(AppContext);

  return (
    <Section
      className="py-0 overflow-hidden light"
      image={bg1}
      position="center bottom"
      overlay
    >
      <Row className="justify-content-center align-items-center pt-8 pt-lg-10 pb-lg-9 pb-xl-0">
        <Col
          md={11}
          lg={8}
          xl={4}
          className="pb-7 pb-xl-9 text-center text-xl-start"
        >
          <h1 className="text-white fw-light">
            Conectamos
            <TypeAnimation
              cursor={!isRTL ? true : false}
              sequence={[
                ' negócios',
                1200,
                ' tecnologia',
                1200,
                ' informação',
                1200
              ]}
              repeat={Infinity}
              wrapper="span"
              className="fw-bold"
            />
            <br />
            para sua empresa e seus clientes
          </h1>
          <p className="lead text-white opacity-75">
            Conectamos negócios com soluções inovadoras em transporte e
            logística
          </p>
          <a
            href="https://axon.log.br"
            className="btn btn-outline-light"
            target="_blank"
            rel="noreferrer"
          >
            Saiba mais
            <FontAwesomeIcon icon="play" transform="shrink-6 down-1 right-5" />
          </a>
        </Col>
        <Col
          xl={{ span: 7, offset: 1 }}
          className="align-self-end mt-4 mt-xl-0"
        >
          <Link to="/" className="img-landing-banner">
            <img
              className="img-fluid"
              src={isDark ? dashboardDark : dashboard}
              alt=""
            />
          </Link>
        </Col>
      </Row>
    </Section>
  );
};

export default Hero;
