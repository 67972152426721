import React, {
  useRef,
  useEffect,
  useState,
  Fragment,
  useCallback
} from 'react';
import mapboxgl from '!mapbox-gl';
import MapboxTraffic from '!@mapbox/mapbox-gl-traffic';
import 'mapbox-gl/dist/mapbox-gl.css';
import './map.css';
import axios from 'axios';
import { baseUrl } from '../../config';
import markerImg from '../../image/custom_marker.png';
import { useSelector } from 'react-redux';

mapboxgl.accessToken =
  'pk.eyJ1IjoiYXhvbmFwaSIsImEiOiJjbGRudWE1dGowNHp3M3hudmJ4MTA2YjEwIn0.GkwFZHDIw2A3Le9BuyYa-A';

const TowerRealTimeMasterPage = () => {
  const mapContainer = useRef(null);
  const map = useRef(null);

  const [lng, setLng] = useState(-58);
  const [lat, setLat] = useState(-16);
  const [pitch, setPitch] = useState(1);
  const [bearing, setBearing] = useState(1);
  const [zoom, setZoom] = useState(4);
  const [isLoading, setIsLoading] = useState(true);

  const [coordinates, setCoordinates] = useState([]);
  const user = JSON.parse(sessionStorage.getItem('user'));

  const filters = useSelector(state => state.filters);
  const configMapa = useSelector(state => state.configMapa); //state.configMapa pega o store configurado

  const imageMarker = new Image();
  imageMarker.src = markerImg;

  const axon_markers = {
    type: 'geojson',
    data: `${baseUrl}/map/v2/getAllTripsMaster`
  };
  const trucks_circle = {
    id: 'trucks-circle',
    type: 'symbol',
    source: 'axon_markers',
    layout: {
      'icon-image': 'custom-marker',
      'icon-allow-overlap': true,
      'icon-anchor': 'bottom',
      'icon-size': 1
    },
    paint: {
      'icon-color': [
        'match',
        ['get', 'status'],
        'Destinado',
        '#3c7a16',
        'Descarga Parcial',
        '#CCC',
        'Viagem',
        '#0f52a9',
        'Vazio',
        '#ffea00',
        'Descarga',
        '#CCC',
        'Carga',
        '#868686',
        '#CCC' // any other store type
      ]
    }
  };

  const trucks_circle_back = {
    id: 'trucks-circle-back',
    type: 'circle',
    source: 'axon_markers',
    layout: {
      visibility: 'visible'
    },
    paint: {
      'circle-radius': {
        stops: [
          [4, 20],
          [16, 120]
        ]
      },
      'circle-opacity': 0.4,
      'circle-pitch-scale': 'viewport',
      'circle-color': [
        'match',
        ['get', 'nac_no_prazo'],
        '0',
        '#ff003d', //atrasado
        '1',
        '#20ec09', //no prazo
        '2',
        '#ffff00', //risco atraso
        '#ffff00' // any other store type
      ]
    }
  };

  const trucks_cluster_count = {
    id: 'trucks-cluster-count',
    type: 'symbol',
    source: 'axon_markers',
    layout: {
      'text-field': ['get', 'message'],
      'text-offset': [0, 0.3],
      'icon-allow-overlap': true,
      'text-size': 20
    },
    paint: {
      'text-color': '#24ff00',
      'text-halo-color': '#000',
      'text-halo-width': 2
    }
  };

  useEffect(
    useCallback(() => {
      const interval = setInterval(() => {
        if (!isLoading) {
          // Só carrega um novo caso anterior já carregado
          getMarkers();
        }
      }, 30000);
      return () => clearInterval(interval);
    }),
    []
  );

  useEffect(
    useCallback(() => {
      const interval = setInterval(() => {
        if (configMapa.conf.visualizacaoDinamica === true) {
          changeZoom();
        }
      }, 10000);
      return () => clearInterval(interval);
    }),
    []
  );

  useEffect(
    useCallback(() => {
      if (map.current) return;
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: configMapa.conf.tipo,
        center: [lng, lat],
        zoom: zoom,
        attributionControl: false
      });

      map.current.on('load', () => {
        console.log('Carregando maps...');

        map.current.addControl(new MapboxTraffic());
        map.current.addControl(new mapboxgl.NavigationControl(), 'top-right');
      });

      map.current.on('move', () => {
        setLng(map.current.getCenter().lng.toFixed(6));
        setLat(map.current.getCenter().lat.toFixed(6));
        setPitch(map.current.getPitch().toFixed(2));
        setBearing(map.current.getBearing().toFixed(2));
        setZoom(map.current.getZoom().toFixed(2));
      });

      map.current.on('click', event => {
        const features = map.current.queryRenderedFeatures(event.point, {
          layers: ['trucks-circle']
        });
        if (!features.length) {
          return;
        }
        const feature = features[0];

        const popup = new mapboxgl.Popup({ offset: [0, -15] })
          .setLngLat(feature.geometry.coordinates)
          .setHTML(
            `<h3>${feature.properties.pedido_shipment}</h3><p>${feature.properties.destino}</p>`
          )
          .addTo(map.current);
      });

      map.current.on('styledata', () => {
        if (!map.current.getSource('axon_markers')) {
          map.current.addSource('axon_markers', axon_markers);
          map.current.addImage('custom-marker', imageMarker, { sdf: true });
        }

        if (!map.current.getLayer('trucks-circle-back')) {
          map.current.addLayer(trucks_circle_back);
          map.current.addLayer(trucks_circle);
          map.current.addLayer(trucks_cluster_count);
        }
      });
      return true;
    }),
    []
  );

  useEffect(() => {
    map.current.setStyle(configMapa.conf.tipo);
  }, [configMapa]);

  useEffect(() => {
    if (!map || !coordinates) return;
    getMarkers();
    return true;
  }, [map, coordinates]);

  const getMarkers = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${sessionStorage.token}`
      },
      params: {
        dataInicial: filters.filters.dataInicial.toString(),
        dataFinal: filters.filters.dataFinal.toString(),
        placa: filters.filters.placa,
        origem: filters.filters.origem,
        status: filters.filters.status
      }
    };

    axios
      .get(`${baseUrl}/map/v2/getAllTrips`, config)
      .then(res => {
        if (res.status === 200) {
          map.current.getSource('axon_markers').setData(res.data);
          setIsLoading(false);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  const changeZoom = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${sessionStorage.token}`
      }
    };

    axios.get(`${baseUrl}/map/v2/getZoomMaster`, config).then(res => {
      map.current.flyTo({
        center: [res.data.lon, res.data.lat],
        zoom: res.data.zoom,
        pitch: parseInt(res.data.pitch),
        bearing: parseInt(res.data.bearing),
        speed: 0.3,
        curve: 1,
        essential: true
      });
    });
  };

  return (
    <Fragment>
      <div style={{ height: '100vh', width: '100wh' }}>
        <div ref={mapContainer} style={{ height: '100vh' }} />
      </div>
    </Fragment>
  );
};

export default TowerRealTimeMasterPage;
