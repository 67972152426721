import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AppContext from 'context/Context';
import Flex from 'components/common/Flex';
import dayjs from 'dayjs';
import axios from 'axios';
import { baseUrl } from '../../../config';
import EditScheduleModal from './EditScheduleModal';

const getCircleStackIcon = (icon, transform) => (
  <span className="fa-stack ms-n1 me-3">
    <FontAwesomeIcon icon="circle" className="text-200 fa-stack-2x" />
    <FontAwesomeIcon
      icon={icon}
      transform={transform ?? ''}
      className="text-primary fa-stack-1x"
      inverse
    />
  </span>
);

const EventModalMediaContent = ({ icon, heading, content, children }) => (
  <Flex className="mt-3">
    {getCircleStackIcon(icon)}
    <div className="flex-1">
      <h6>{heading}</h6>
      {children || <p className="mb-0 text-justify">{content}</p>}
    </div>
  </Flex>
);

const CalendarEventModal = ({
  setIsOpenEventModal,
  isOpenEventModal,
  modalEventContent
}) => {
  const {
    config: { isDark }
  } = useContext(AppContext);

  const deleteEvent = id => {
    const config = {
      headers: {
        Authorization: `Bearer ${sessionStorage.token}`
      }
    };

    axios
      .delete(`${baseUrl}/desk/calendario/${id}`, config)
      .then(response => {
        if (response.status === 201 || response.status === 301) {
          handleClose();
        } else {
          console.log('Algum erro ocorreu ao excluir o registro.');
        }
      })
      .catch(error => {
        console.log(`Erro ${error}`);
      });
  };

  const handleClose = () => {
    setIsOpenEventModal(!isOpenEventModal);
  };

  const handleOpen = () => {
    setIsOpenEditScheduleModal(!isOpenEditScheduleModal);
  };

  const { title, end, start, id } = isOpenEventModal && modalEventContent.event;
  const { description, organizer, link } =
    isOpenEventModal && modalEventContent.event.extendedProps;
  const [isOpenEditScheduleModal, setIsOpenEditScheduleModal] = useState(false);

  return (
    <Modal
      show={isOpenEventModal}
      onHide={handleClose}
      contentClassName="border"
      centered
    >
      <Modal.Header
        closeButton
        closeVariant={isDark ? 'white' : undefined}
        className="bg-light px-card border-bottom-0"
      >
        <Modal.Title>
          <h5 className="mb-0">{title}</h5>
          {organizer && (
            <p className="mb-0 fs--1 mt-1 fw-normal">
              by <a href="#!">{organizer}</a>
            </p>
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-card pb-card pt-1 fs--1">
        {description && (
          <EventModalMediaContent
            icon="align-left"
            heading="Descrição"
            content={description}
          />
        )}
        {(end || start) && (
          <EventModalMediaContent icon="calendar-check" heading="Data">
            <p className="mb-1">
              {dayjs(start).format('DD/MM/YYYY  hh:mm')}
              {end && (
                <>
                  {'  '} a <br />
                  {dayjs(end).format('DD/MM/YYYY  hh:mm')}
                </>
              )}
            </p>
          </EventModalMediaContent>
        )}
        {link && (
          <EventModalMediaContent icon="location-arrow" heading="Url">
            <a className="mb-1">{link}</a>
          </EventModalMediaContent>
        )}
      </Modal.Body>
      <Modal.Footer className="bg-light px-card border-top-0">
        <Button
          variant="falcon-default"
          size="sm"
          onClick={() => {
            handleOpen();
          }}
        >
          <FontAwesomeIcon icon="pencil-alt" className="fs--2 me-2" />
          <span>Editar</span>
        </Button>
        <Button
          variant="falcon-primary"
          size="sm"
          onClick={() => {
            deleteEvent(id);
          }}
        >
          <span>Excluir</span>
        </Button>

        <EditScheduleModal
          isOpenEditScheduleModal={isOpenEditScheduleModal}
          setIsOpenEditScheduleModal={setIsOpenEditScheduleModal}
          id={id}
        />
      </Modal.Footer>
    </Modal>
  );
};

EventModalMediaContent.propTypes = {
  icon: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  content: PropTypes.string,
  children: PropTypes.node
};

CalendarEventModal.propTypes = {
  setIsOpenEventModal: PropTypes.func.isRequired,
  isOpenEventModal: PropTypes.bool.isRequired,
  modalEventContent: PropTypes.object.isRequired
};

export default CalendarEventModal;
