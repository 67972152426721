import React, { useState, useEffect, Fragment } from 'react';
import { ButtonGroup, Card, Col, Form, Row } from 'react-bootstrap';
import {
  Button,
  CardBody,
  CardHeader,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Spinner,
  Table
} from 'reactstrap';
import PageHeader from '../../../components/pages/pageHeader';
import moment from 'moment/moment';
import axios from 'axios';
import { baseUrl } from '../../../config';

import '../../eva/styleEva.css';

import AlertErrorGetData from 'components/alert/errorGetData';
import SuccessSaveData from 'components/alert/sucessSaveData';
import AlertErrorSaveData from 'components/alert/errorSaveData';
import Swal from 'sweetalert2';
import { FaPowerOff, FaTrashAlt } from 'react-icons/fa';

const CompartilharPainel = () => {
  const [dataInicial, setDataInicial] = useState(moment().format(`YYYY-MM-01`));
  const [dataFinal, setDataFinal] = useState(moment().format(`YYYY-MM-DD`));
  const [isLoading, setIsLoading] = useState(true);
  const [cnpj, setCnpj] = useState('');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [dataRes, setDataRes] = useState([]);
  const [dataResFilter, setDataResFilter] = useState(dataRes);
  const [show, setShow] = useState(true);

  const usuario = JSON.parse(sessionStorage.getItem('user'));
  const handleStatus = user => {
    return user.status == 2 ? 0 : user.status == 1 ? 2 : 0;
  };
  const filterSituacao = (arr, situacaoFiltro) => {
    if (situacaoFiltro == 'todos') {
      return setDataResFilter(arr);
    }
    return setDataResFilter(
      arr.filter(item => item.situacao === situacaoFiltro)
    );
  };
  const sendData = async () => {
    const valueData = {
      cnpj: cnpj,
      nome: name,
      email: email,
      id_grupo: usuario.id_grupo_cliente,
      user_solicitante: usuario.id
    };
    const config = {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${window.sessionStorage.getItem('token')}`
      }
    };

    await axios
      .post(`${baseUrl}/kpis/share`, valueData, config)
      .then(response => {
        console.log(response);
        AlertErrorSaveData('Teste');
        SuccessSaveData('Solicitação enviada com sucesso');
      })
      .catch(err => {
        console.log(err.message);
        AlertErrorSaveData('Erro ao enviar a solicitaçãos, tente novamente');
      })
      .finally(() => {
        getData();
        setIsLoading(false);
      });
  };

  const getData = async () => {
    const config = {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${window.sessionStorage.getItem('token')}`
      }
    };

    await axios
      .get(`${baseUrl}/kpis/share?key=${usuario.id_grupo_cliente}`, config)
      .then(response => {
        setDataRes(response.data);
        setDataResFilter(response.data);
        setShow(true);
      })
      .catch(err => {
        console.log(err);
        AlertErrorGetData(
          'Ocorreu um erro, recarregue a página, se o erro persistir contate o suporte '
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const turnOff = async id => {
    const config = {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${window.sessionStorage.getItem('token')}`
      }
    };

    await axios
      .post(`${baseUrl}/kpis/endShare/${id}`, config)
      .then(response => {
        console.log(response);
        SuccessSaveData(response.data.message);
      })
      .catch(err => {
        AlertErrorGetData(
          'Ocorreu um erro, recarregue a página, se o erro persistir contate o suporte'
        );
      })
      .finally(() => {
        setIsLoading(true);
        getData();
      });
  };
  const turnOn = async id => {
    const config = {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${window.sessionStorage.getItem('token')}`
      }
    };

    await axios
      .post(`${baseUrl}/kpis/reactivateShare/${id}`, config)
      .then(response => {
        console.log(response);
        SuccessSaveData(response.data.message);
      })
      .catch(err => {
        AlertErrorGetData(
          'Ocorreu um erro, recarregue a página, se o erro persistir contate o suporte'
        );
      })
      .finally(() => {
        setIsLoading(true);
        getData();
      });
  };
  const turnDelete = async id => {
    await Swal.fire({
      title:
        'Tem certeza que deseja exlcuir a solicitação de compartilhamento?',
      showDenyButton: true,
      confirmButtonColor: '#2b7be5',
      confirmButtonText: 'Excluir',
      denyButtonText: `Cancelar`
    }).then(result => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        Swal.fire('Saved!', '', 'success');
        const config = {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${window.sessionStorage.getItem('token')}`
          }
        };

        axios
          .delete(`${baseUrl}/kpis/share/${id}`, config)
          .then(response => {
            console.log(response);
            SuccessSaveData('Solicitação excluída com sucesso!');
          })
          .catch(err => {
            AlertErrorGetData(
              'Ocorreu um erro, recarregue a página, se o erro persistir contate o suporte'
            );
          })
          .finally(() => {
            setIsLoading(false);
            getData();
          });
      }
      // else  (result.isDenied) {

      // }
    });
  };

  useEffect(() => {
    getData();
  }, [isLoading]);

  return (
    <Fragment>
      <PageHeader
        title="Compartilhamento de painel"
        description=" "
        // eslint-disable-next-line
        children={
          <>
            <Form className="g-5">
              <Row>
                <Col sm={12} md={12} lg={4} xl={4}>
                  <FormGroup>
                    <Label>Nome</Label>
                    <Input
                      type="text"
                      name="text"
                      defaultValue={name}
                      onChange={e => {
                        setName(e.target.value);
                      }}
                    />
                  </FormGroup>
                </Col>

                <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                  <FormGroup>
                    <Label>E-mail</Label>
                    <Input
                      type="text"
                      name="text"
                      defaultValue={email}
                      onChange={e => {
                        setEmail(e.target.value);
                      }}
                    />
                  </FormGroup>
                </Col>

                <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                  <FormGroup>
                    <Label>CNPJ</Label>
                    <Input
                      type="text"
                      name="cnpj"
                      defaultValue={cnpj}
                      onChange={e => {
                        setCnpj(e.target.value);
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row className="mt-4">
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Button onClick={() => sendData()}>
                    Solicitar liberação
                  </Button>
                </Col>
              </Row>
            </Form>
          </>
        }
      />

      {isLoading ? (
        <Row
          style={{ height: '100px' }}
          className="m-1 mt-3 d-flex justify-content-center"
        >
          <Col className="h-100 d-flex justify-content-center">
            <Spinner> </Spinner>
          </Col>
        </Row>
      ) : (
        <Row className="m-1 mt-3">
          <Card>
            <CardHeader className="d-flex justify-content-start ">
              <ButtonGroup className="w-40">
                <Button
                  className="filterCompartilhamentoPainel"
                  onClick={() => filterSituacao(dataRes, 'todos')}
                >
                  Todos
                </Button>
                <Button
                  className="filterCompartilhamentoPainel"
                  onClick={() => filterSituacao(dataRes, 'liberado')}
                >
                  Ativos
                </Button>
                <Button
                  className="filterCompartilhamentoPainel"
                  onClick={() => filterSituacao(dataRes, 'inativo')}
                >
                  Inativos
                </Button>
                <Button
                  className="filterCompartilhamentoPainel"
                  onClick={() => filterSituacao(dataRes, 'pendente')}
                >
                  Pendentes
                </Button>
              </ButtonGroup>
            </CardHeader>
            <Table striped hover>
              <thead className=" border border-bottom-10">
                <tr>
                  <th className="d-flex">
                    <b>Nome</b>
                  </th>
                  <th className="text-center">
                    <b>E-mail</b>
                  </th>
                  <th className=" text-center">
                    <b>CNPJ</b>
                  </th>
                  <th className="text-center">
                    <b>Compartilhamento</b>
                  </th>
                  <th className="text-center"> </th>
                </tr>
              </thead>
              {dataResFilter.length === 0 ? (
                <h5
                  style={{
                    margin: '1em',
                    alignContent: 'center',
                    justifyContent: 'center'
                  }}
                >
                  Sem registros
                </h5>
              ) : (
                <tbody>
                  {dataResFilter.map((userComp, index) => (
                    <tr key={index}>
                      <td>{userComp.nome}</td>
                      <td className="text-center">{userComp.email}</td>
                      <td className="text-center">{userComp.cnpj}</td>
                      <td className="text-center">
                        {userComp.situacao.toUpperCase()}
                      </td>
                      {console.log(userComp.user_id)}

                      <td>
                        {userComp.status == 0 ? (
                          <Button
                            id={userComp.user_id}
                            style={{
                              color: 'grey'
                            }}
                            color="brand"
                            onClick={e => {
                              console.log(userComp.id);
                              turnDelete(userComp.id);
                            }}
                          >
                            <FaTrashAlt />
                          </Button>
                        ) : userComp.status == 2 ? (
                          <Button
                            style={{
                              color: 'grey'
                            }}
                            color="brand"
                            onClick={e => {
                              console.log(userComp.user_id);
                              turnOn(userComp.user_id);
                            }}
                          >
                            <FaPowerOff />
                          </Button>
                        ) : (
                          <Button
                            style={{
                              color: '#007CC2'
                            }}
                            color="brand"
                            onClick={e => turnOff(userComp.user_id)}
                          >
                            <FaPowerOff />
                          </Button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
            </Table>
          </Card>
        </Row>
      )}
    </Fragment>
  );
};

export default CompartilharPainel;
