import evaPlant from '../../assets/img/eva/eva-plant-2.png';
import React from 'react';
import './styleEva.css';

const EvaGracinha = () => {
  return (
    <div className="eva">
      <img src={evaPlant} className="evinha" />
    </div>
  );
};

export default EvaGracinha;
