import { getColor, rgbaColor } from 'helpers/utils';
import React, { useContext } from 'react';
import { Pie } from 'react-chartjs-2';
import { Col, Row } from 'react-bootstrap';
import AppContext from '../../context/Context';
import PropTypes from 'prop-types';
import EntregasAtrasadas from './EntregasAtrasadas';

const ChartPie = ({ dados }) => {
  const controlavel = dados.total.controlavel;
  const incontrolavel = dados.total.incontrolavel;
  const controlavelperc = (dados.total.controlavel * 100) / dados.total.geral;
  const incontrolavelperc =
    (dados.total.incontrolavel * 100) / dados.total.geral;
  const contolavelLabel = 'Controláveis  ' + controlavelperc.toFixed(2) + '%';
  const incontolavelLabel =
    'Não controláveis  ' + incontrolavelperc.toFixed(2) + '%';
  console.log(controlavel);
  console.log(incontrolavel);
  const data = {
    datasets: [
      {
        data: [controlavel, incontrolavel],
        backgroundColor: [
          rgbaColor(getColor('info'), 1),
          rgbaColor(getColor('warning'), 1)
        ],
        borderWidth: 1,
        borderColor: getColor('100')
      }
    ],
    labels: [contolavelLabel, incontolavelLabel]
  };

  const options = {
    maintainAspectRatio: true,
    color: 'white'
  };

  return <Pie data={data} options={options} style={{ height: '60%' }} />;
};

ChartPie.propTypes = {
  dados: PropTypes.array.isRequired
};

export default ChartPie;
