import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import logo from 'assets/img/illustrations/logo-polo.png';
import logo_white from 'assets/img/illustrations/logo-polo.png';
import AppContext from '../../context/Context';

const LogoCliente = ({ at, width, className, ...rest }) => {
  const {
    config: { isDark }
  } = useContext(AppContext);

  return <img src={isDark ? logo_white : logo} alt="Logo" width={width} />;
};

LogoCliente.propTypes = {
  at: PropTypes.oneOf(['navbar-vertical', 'navbar-top', 'auth']),
  width: PropTypes.number,
  className: PropTypes.string,
  textClass: PropTypes.string
};

LogoCliente.defaultProps = { at: 'auth', width: 58 };

export default LogoCliente;
