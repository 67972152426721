export default [
  {
    media: {
      icon: ['fas', 'chevron-right'],
      color: 'info',
      className: 'fs-4'
    },
    title: 'Flexibilidade',
    description:
      'A logística é um setor dinâmico, a Axon se adapta rapidamente a mudanças no mercado e/ou as necessidades de nossos clientes.'
  },
  {
    media: {
      icon: ['fas', 'rocket'],
      color: 'success',
      className: 'fs-5'
    },
    title: 'Tecnologia',
    description:
      'Somos uma logitech, realizamos constantes automações de nossos processos, acompanhamos em tempo real todas as entregas e gerenciamos de maneira eficiente a cadeia de suprimentos.'
  },
  {
    media: { icon: ['fas', 'shield'], color: 'info', className: 'fs-6' },
    title: 'Gestão de riscos',
    description:
      'Todos os nossos processos são monitorados e respaldados por planos de contingência. Sabemos lidar e solucionar os riscos.'
  }
];
