import React from 'react';
import { Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import SoftBadge from 'components/common/SoftBadge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import PropTypes from 'prop-types';
import ViagensEmAbertoGrafico from './ViagensEmAbertoGrafico';
import { Spinner } from 'reactstrap';

const ViagensEmAberto = ({ totalViagens, totalViagensEmAberto, load }) => {
  return (
    <Card className="h-md-100">
      <Card.Header className="pb-0">
        <h6 className="mb-0 mt-2">
          Viagens em aberto
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip>
                Contagem de viagens pré-agendadas e em andamento.
              </Tooltip>
            }
          >
            <span>
              <FontAwesomeIcon
                icon={['far', 'question-circle']}
                transform="shrink-1"
                className="ms-1 text-400"
                id="weeklySalesTooltip"
              />
            </span>
          </OverlayTrigger>
        </h6>
      </Card.Header>
      <Card.Body as={Flex} alignItems="end" justifyContent="between">
        {load ? (
          <Spinner> </Spinner>
        ) : (
          <div>
            <h2 className="mb-1 text-700 fw-normal lh-1 fs-2">
              {totalViagensEmAberto}
            </h2>
            <SoftBadge pill bg="warning" className="me-2 fs--2">
              <FontAwesomeIcon icon={['fas', 'angle-right']} className="me-1" />
              {((totalViagensEmAberto / totalViagens) * 100).toFixed(2)}%
            </SoftBadge>
          </div>
        )}
        {load ? (
          <Spinner> </Spinner>
        ) : (
          <div>
            <ViagensEmAbertoGrafico
              totalViagensEmAberto={totalViagensEmAberto}
              totalViagens={totalViagens}
            />
            <p className="mb-0 mt-4 text-center fs--2 text-500">
              Total: <span className="text-800"> {totalViagens} </span>
            </p>
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

ViagensEmAberto.propTypes = {
  totalViagens: PropTypes.number,
  totalViagensEmAberto: PropTypes.number,
  load: PropTypes.bool
};

export default ViagensEmAberto;
