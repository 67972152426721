import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Tooltip, OverlayTrigger } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import * as echarts from 'echarts/core';
import { BarChart } from 'echarts/charts';
import {
  GridComponent,
  TooltipComponent,
  TitleComponent
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import SoftBadge from 'components/common/SoftBadge';
import classNames from 'classnames';
import Background from '../../../components/common/Background';
import corner2 from '../../../assets/img/illustrations/corner-2.png';
import { Spinner } from 'reactstrap';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  BarChart,
  CanvasRenderer
]);

const PesoMedio = ({ peso, viagens, load }) => {
  return (
    <Card
      className="overflow-hidden w-100 h-md-100"
      style={{ minWidth: '12rem' }}
    >
      <Background image={corner2} className="bg-card" />
      <Card.Header className="pb-0">
        <h6 className="mb-0 mt-2">
          Peso Médio
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip>
                Peso total carregado / total de viagens no periodo
              </Tooltip>
            }
          >
            <span>
              <FontAwesomeIcon
                icon={['far', 'question-circle']}
                transform="shrink-1"
                className="ms-1 text-400"
                id="weeklySalesTooltip"
              />
            </span>
          </OverlayTrigger>
        </h6>
      </Card.Header>

      <Card.Body as={Flex} alignItems="end" justifyContent="between">
        {load ? (
          <Spinner> </Spinner>
        ) : (
          <div>
            <h2 className={classNames('fs-2', 'mb-1 text-700 fw-normal lh-1')}>
              {viagens > 0
                ? (peso / viagens).toLocaleString('pt-BR', {
                    maximumFractionDigits: 2
                  })
                : 0}{' '}
              t
            </h2>
            <SoftBadge pill bg="success" className="me-2 fs--2">
              últimos 30 dias
            </SoftBadge>
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

PesoMedio.propTypes = {
  media: PropTypes.number,
  peso: PropTypes.number,
  viagens: PropTypes.number,
  load: PropTypes.bool
};

export default PesoMedio;
