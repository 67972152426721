import React, { useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CardDropdown from 'components/common/CardDropdown';
import ComparativoSemanalGrafico from './ComparativoSemanalGrafico';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const ComparativoSemanal = ({
  periodo1,
  periodo2,
  periodo3,
  periodo4,
  // eslint-disable-next-line react/prop-types
  label,
  title,
  load
}) => {
  const [stats] = useState([
    {
      label: 'Semana 1',
      color: 'primary'
    },
    {
      label: 'Semana 2',
      color: 'success'
    },
    {
      label: 'Semana 3',
      color: 'info'
    },
    {
      label: 'Semana 4',
      color: 'warning'
    }
  ]);
  return (
    <Card className="h-100 mb-3">
      <FalconCardHeader
        title={title}
        titleTag="h6"
        className="py-2"
        light
        endEl={<CardDropdown />}
      />
      <Card.Body>
        <Row>
          <Col md="auto" className="mt-md-0">
            <Row className="flex-md-column justify-content-between h-md-100 ms-0">
              {stats.map((stat, index) => (
                <Col
                  key={stat.label}
                  className={classNames('pt-3', {
                    'border-end border-md-end-0 border-md-bottom': index !== 2,
                    'pt-md-4': index !== 0
                  })}
                >
                  <h6 className="fs--2 text-7">
                    <FontAwesomeIcon
                      icon="circle"
                      className={`text-${stat.color} me-2`}
                    />
                    {stat.label}
                  </h6>
                </Col>
              ))}
            </Row>
          </Col>
          <Col md="auto" className="echart-active-users-report-container">
            <ComparativoSemanalGrafico
              periodo1={periodo1}
              periodo2={periodo2}
              periodo3={periodo3}
              periodo4={periodo4}
              label={label}
              load={load}
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

ComparativoSemanal.propTypes = {
  periodo1: PropTypes.array,
  periodo2: PropTypes.array,
  periodo3: PropTypes.array,
  periodo4: PropTypes.array,
  title: PropTypes.string,
  load: PropTypes.bool
};

export default ComparativoSemanal;
