import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import truckCarregamento from 'assets/img/icons/truckCarga.svg';
import CountUp from 'react-countup';

// eslint-disable-next-line react/prop-types
const SmallCard = ({ description, value }) => {
  return (
    <Row className="align-items-sm-center">
      <Col xs="auto">
        <img src={truckCarregamento} alt="" width={75} className="me-2" />
      </Col>
      <Col>
        <Row className="align-items-center">
          <Col className="pe-xl-8">
            <h5 className="fs-0 mb-3 mb-sm-0 text-primary">{description}</h5>
            <div className="text-warning fs-3 fw-bold">
              <CountUp
                end={value / 1000}
                duration={4}
                separator="."
                decimal=","
                suffix=" ton."
              />
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default SmallCard;
