import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, CardHeader } from 'reactstrap';
import Flex from '../common/Flex';
import { Circle } from 'react-es6-progressbar.js';
import { rgbaColor, themeColors } from '../../helpers/utils';

// eslint-disable-next-line react/prop-types
const IndicadorCircular = ({ total, saved, title, iconButton }) => {
  const options = {
    color: themeColors.primary,
    progress: saved / total,
    strokeWidth: 5,
    trailWidth: 5,
    trailColor: rgbaColor('#000', 0.15),
    easing: 'easeInOut',
    duration: 3000,
    svgStyle: {
      'stroke-linecap': 'round',
      display: 'block',
      width: '100%'
    },
    text: { autoStyleContainer: false },
    // Set default step function for all animate calls
    step: (state, circle) => {
      const percentage = Math.round(circle.value() * 100);
      circle.setText(`<span class='value'>${percentage}<b>%</b></span>`);
    }
  };

  return (
    <Card className="h-100">
      <CardHeader>
        {title}
        {'  '}
        {iconButton}
      </CardHeader>
      <CardBody tag={Flex} align="center" justify="center" className="m-auto">
        <div>
          <Circle
            progress={saved / total}
            options={options}
            container_class="progress-circle progress-circle-dashboard"
            container_style={{ width: '80%', height: '70%' }}
          />
          <div className="text-center mt-5">
            <h6 className="mb-1">
              Total: {total}
              <br />
              On-time: {saved}
            </h6>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

IndicadorCircular.propTypes = {
  total: PropTypes.number.isRequired,
  saved: PropTypes.number.isRequired
};

export default IndicadorCircular;
