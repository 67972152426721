import React, { Fragment, useEffect, useState } from 'react';

import { Row, Col } from 'react-bootstrap';
import CargasCard from './cargasComponents/cardcargas';
import TableTopTen from './cargasComponents/tableTop10';
import Grafico from './cargasComponents/grafico';
import axios from 'axios';
import { baseUrl } from 'config';
import PageHeader from 'components/pages/pageHeader';
import { Button, Input, InputGroup } from 'reactstrap';
import moment from 'moment/moment';

const Cargas = () => {
  const [loading, setLoading] = useState(true); //Mudar para true
  const [data, setData] = useState([]);
  const [total, setTotal] = useState([]);
  const [agrupador, setAgrupado] = useState([]);
  const [topDez, setTopDez] = useState([]);
  const [reload, setReload] = useState(false);
  // eslint-disable-next-line
  const [mes, setMes] = useState(moment().format('MM'));
  const [dataInicial, setDataInicial] = useState(
    moment().format(`YYYY-${mes}-01`)
  );
  const [dataFinal, setDataFinal] = useState(moment().format(`YYYY-MM-DD`));
  const usuario = JSON.parse(sessionStorage.getItem('user'));
  function BigNumber(valor) {
    const options = {
      significantDigits: 2,
      thousandsSeparator: '.',
      decimalSeparator: ','
    };

    if (typeof valor !== 'number') valor = 0.0;
    valor = valor.toFixed(options.significantDigits);

    const [currency, decimal] = valor.split('.');
    return `${currency.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      options.thousandsSeparator
    )}${options.decimalSeparator}${decimal}`;
  }
  const getData = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${sessionStorage.token}`
      }
    };

    axios
      .get(
        `${baseUrl}/kpis/getLoadsTotal?codGrupoNegociador=${usuario.id_grupo_cliente}&dataInicio=${dataInicial}&dataFim=${dataFinal}`,
        config
      )
      .then(response => {
        console.log(response.data);
        setData(response.data);
        setTotal(response.data.total);
        setAgrupado(response.data.agrupado);
        setTopDez(response.data.topDez);
      })
      .catch(error => {
        console.log(`Erro ${error}`);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getData();
  }, []);
  console.log(data);
  return (
    <Fragment>
      <PageHeader
        title="Cargas"
        description=" "
        // eslint-disable-next-line
        children={
          <Row>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <InputGroup size="sm">
                <Input
                  type="date"
                  name="data inicial"
                  defaultValue={dataInicial}
                  onChange={e => {
                    setDataInicial(e.target.value);
                  }}
                  className="custom-input"
                />
                <Input
                  type="date"
                  name="data final"
                  defaultValue={dataFinal}
                  onChange={e => {
                    setDataFinal(e.target.value);
                  }}
                  className="custom-input"
                />
                <Button
                  onClick={() => {
                    setReload(true);
                    getData();
                  }}
                >
                  Buscar
                </Button>
              </InputGroup>
            </Col>
            {loading ? (
              <Col sm={10} md={1} lg={1}>
                <div className="loader">
                  <span className="loader__element">●</span>
                  <span className="loader__element">●</span>
                  <span className="loader__element">●</span>
                </div>
              </Col>
            ) : (
              <></>
            )}
          </Row>
        }
      />
      <Row className="g-3">
        <Col xs={12} sm={12} m={6} lg={6} xl={6}>
          <Row className="m-3">
            <Col xs={6} sm={6} m={6} lg={6} xl={6}>
              <CargasCard
                title={'Carga'}
                data={total.quantidade}
                load={loading}
              />
            </Col>
            <Col xs={6} sm={6} m={6} lg={6} xl={6}>
              <CargasCard
                title={'Total'}
                data={
                  total.valor == null
                    ? 'R$ ' + 0
                    : 'R$ ' +
                      Number(total.valor).toLocaleString('pt-BR', {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                      })
                }
                load={loading}
              />
            </Col>
          </Row>
          <Row className="m-3">
            <Col xs={6} sm={6} m={6} lg={6} xl={6}>
              <CargasCard
                title={'Peso Bruto'}
                data={
                  total.peso == null
                    ? 0 + ' t'
                    : Number(total.peso).toLocaleString('pt-BR', {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                      }) + ' t'
                }
                load={loading}
              />
            </Col>
            <Col xs={6} sm={6} m={6} lg={6} xl={6}>
              <CargasCard
                title={'Cubagem'}
                data={
                  total.cubagem == null
                    ? 0 + ' m³'
                    : Number(total.cubagem).toLocaleString('pt-BR', {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                      }) + ' m³'
                }
                load={loading}
              />
            </Col>
          </Row>
          <Col
            xs={12}
            sm={12}
            m={12}
            lg={12}
            xl={12}
            className=" align-items-center justify-content-center"
          >
            <Grafico agrupador={agrupador} />
          </Col>
          <Row className="m-3"></Row>
        </Col>
        <Col xs={12} sm={12} m={6} lg={6} xl={6} className="mt-4">
          <TableTopTen topDez={topDez} load={loading} />
        </Col>
      </Row>
    </Fragment>
  );
};
export default Cargas;
