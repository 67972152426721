import React, { Fragment, useEffect, useState } from 'react';
import CardSummary from '../../../components/kpis/CardSummary';
import { Col, Row } from 'react-bootstrap';
import './styleCarregamento.css';
import '../kpis.css';
import { Button, Input, InputGroup } from 'reactstrap';
import PageHeader from '../../../components/pages/pageHeader';
import axios from 'axios';
import IndicadorCircular from '../../../components/kpis/IndicadorCircular';
import { baseUrl } from '../../../config';
import CarregamentosChart from '../../dashboards/viagens/totalsales/CarregamentosChart';
import SmallCard from '../../../components/kpis/SmallCard';
import LoadCarregamento from '../../../components/load-carregamento';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CarregamentosKPI = () => {
  const [data, setData] = useState([]);
  const [load, setLoad] = useState(true);
  const [reload, setReload] = useState(false);
  // eslint-disable-next-line
  const [mes, setMes] = useState(moment().format('MM'));
  const [dataInicial, setDataInicial] = useState(
    moment().format(`YYYY-${mes}-01`)
  );
  const [dataFinal, setDataFinal] = useState(moment().format(`YYYY-MM-DD`));
  const [atrasado, setAtrasado] = useState([]);
  const [ontime, setOntime] = useState([]);
  const [label, setLabel] = useState([]);
  const idGrupo = JSON.parse(sessionStorage.getItem('user'));

  const getData = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${sessionStorage.token}`
      }
    };

    axios
      .get(
        `${baseUrl}/kpis/carregamento/${idGrupo.id_cliente}/${dataInicial}/${dataFinal}`,
        config
      )
      .then(response => {
        setAtrasado([]);
        setOntime([]);
        setLabel([]);
        setData(response.data);
        // eslint-disable-next-line
        response.data.atrasosAno.map((i, k) => {
          // eslint-disable-next-line no-shadow
          return setLabel(label => [...label, i.mes]);
        });
        // eslint-disable-next-line
        response.data.atrasosAno.map((i, k) => {
          // eslint-disable-next-line no-shadow
          return setAtrasado(atrasado => [...atrasado, parseInt(i.atrasado)]);
        });
        // eslint-disable-next-line
        response.data.atrasosAno.map((i, k) => {
          // eslint-disable-next-line no-shadow
          return setOntime(ontime => [...ontime, parseInt(i.ontime)]);
        });
        setLoad(false);
        setReload(false);
      })
      .catch(error => {
        console.log(`Erro ${error}`);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Fragment>
      <PageHeader
        title="Indicadores de Carregamentos"
        description=" "
        // eslint-disable-next-line
        children={
          <Row>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <InputGroup size="sm">
                <Input
                  type="date"
                  name="data inicial"
                  defaultValue={dataInicial}
                  onChange={e => {
                    setDataInicial(e.target.value);
                  }}
                  className="custom-input"
                />
                <Input
                  type="date"
                  name="data final"
                  defaultValue={dataFinal}
                  onChange={e => {
                    setDataFinal(e.target.value);
                  }}
                  className="custom-input"
                />
                <Button
                  onClick={() => {
                    setReload(true);
                    getData();
                  }}
                >
                  Buscar
                </Button>
              </InputGroup>
            </Col>
            {reload && (
              <Col sm={10} md={1} lg={1}>
                <div className="loader">
                  <span className="loader__element">●</span>
                  <span className="loader__element">●</span>
                  <span className="loader__element">●</span>
                </div>
              </Col>
            )}
          </Row>
        }
      />
      {load ? (
        <>
          <LoadCarregamento
            msg1Line={'Aguarde o carregamento dos dados.'}
            msg2Line={'Isso pode demorar um pouco.'}
          />
        </>
      ) : (
        <>
          <Row className="carregamentosIndicador">
            <Col
              sm={12}
              md={8}
              lg={8}
              className="carregamentosIndicador__grafico"
            >
              <CarregamentosChart
                atrasado={atrasado}
                ontime={ontime}
                label={label}
              />
            </Col>
            <Col
              sm={12}
              md={4}
              lg={4}
              className="carregamentosIndicador__onTime"
            >
              <IndicadorCircular
                total={parseInt(data.totalCarregamentos)}
                saved={parseInt(data.totalOntimeCarga)}
                title="Carregamentos ontime (Carga)"
                iconButton={
                  <a
                    className="font-weight-semi-bold fs--1 text-nowrap position-absolute z-index-2 ms-2"
                    href={`${baseUrl}/kpis/excel/totalCarregamentos/${idGrupo.id_cliente}/${dataInicial}/${dataFinal}`}
                  >
                    <FontAwesomeIcon
                      icon="cloud-download-alt"
                      transform="down-1.5"
                      className="ml-1"
                    />
                  </a>
                }
              />
            </Col>
          </Row>
          <Row className="carregamentosCards">
            <Col className="carregamentosCards__child">
              <CardSummary
                rate={data.totalNaoAceitos}
                title="Carregamentos não aceitos"
                color="info"
                linkText=""
                to={`${baseUrl}/kpis/excel/carregamentosCancelados/${idGrupo.id_cliente}/${dataInicial}/${dataFinal}`}
                icon="cloud-download-alt"
              >
                {data.percNaoAceitos}%
              </CardSummary>
            </Col>
            <Col className="carregamentosCards__child">
              <CardSummary
                title="Carregamentos de emergência"
                color="info"
                linkText=""
                to={`${baseUrl}/kpis/excel/carregamentosEmergencia/${idGrupo.id_cliente}/${dataInicial}/${dataFinal}`}
                icon="cloud-download-alt"
              >
                {data.totalAceitoEmergencia}
              </CardSummary>
            </Col>
            <Col className="carregamentosCards__child">
              <CardSummary
                title="Qtde. de cargas incorretas (troca de endereço de entrega)"
                color="warning"
                linkText=""
                to={
                  data.quantidadeCargaIncorreto
                    ? `${baseUrl}/kpis/excel/carregamentosIncorretos/${idGrupo.id_cliente}/${dataInicial}/${dataFinal}`
                    : '#'
                }
                icon="cloud-download-alt"
                rate=""
              >
                {data.quantidadeCargaIncorreto}
              </CardSummary>
            </Col>
            <Col className="carregamentosCards__child">
              <CardSummary
                // rate="06:17:57"
                title="Tempo médio de carregamento"
                color="info"
                linkText=""
                to=""
              >
                {data.tempoMedioCarregamento.tempo_medio}
              </CardSummary>
            </Col>
            <Col className="carregamentosCards__child">
              <CardSummary
                title="No-Show"
                color="info"
                linkText=""
                to={`${baseUrl}/kpis/excel/noShow/${idGrupo.id_cliente}/${dataInicial}/${dataFinal}`}
                icon="cloud-download-alt"
                rate=""
              >
                {data.indiceNoShow}
              </CardSummary>
            </Col>
          </Row>
          <Row className="carregamentos">
            <SmallCard
              description="Peso total carregado:"
              value={data.pesoCarregado}
            />
          </Row>
        </>
      )}
    </Fragment>
  );
};

export default CarregamentosKPI;
