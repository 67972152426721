import Swal from 'sweetalert2';

const SuccessSaveData = text => {
  return Swal.fire({
    position: 'top-end',
    icon: 'success',
    title: 'Sucesso!',
    text: text,
    showConfirmButton: false,
    timer: 1500
    // cancelButtonText: 'Ok',
    // reverseButtons: true,
    // confirmButtonColor: '#2b7be5'
  });
};

export default SuccessSaveData;
