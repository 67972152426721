import Swal from 'sweetalert2';

const AlertErrorGetData = text => {
  return Swal.fire({
    icon: 'warning',
    title: 'Aviso',
    text: text,
    footer: 'Se o erro persistir contate o suporte!',
    cancelButtonText: 'Ok',
    reverseButtons: true,
    confirmButtonColor: '#2b7be5'
  });
};
export default AlertErrorGetData;
