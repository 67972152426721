import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  conf: {
    tipo: 'mapbox://styles/axonapi/cldnuql8u006d01o02w9bxz4h',
    visualizacaoDinamica: true
  }
};

const confMapaSlice = createSlice({
  name: 'confMapa',
  initialState,
  reducers: {
    updateConfMapa: (state, action) => {
      state.conf[action.payload.label] = action.payload.value;
    }
  }
});

export const { updateConfMapa } = confMapaSlice.actions;
export default confMapaSlice.reducer;
