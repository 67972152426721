import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Background from '../common/Background';
import corner1 from '../../assets/img/illustrations/corner-1.png';
import corner2 from '../../assets/img/illustrations/corner-2.png';
import corner3 from '../../assets/img/illustrations/corner-3.png';
import { Card } from 'react-bootstrap';

const getImage = color => {
  switch (color) {
    case 'warning':
      return corner1;
    case 'info':
      return corner2;
    case 'success':
      return corner3;
    default:
      return corner1;
  }
};

const getContentClassNames = color => {
  const contentClassNames =
    'display-4 fs-4 mb-2 font-weight-normal text-sans-serif';
  if (color === 'success') return contentClassNames;
  return `${contentClassNames} text-${color}`;
};

const CardSummary = ({ title, rate, linkText, to, color, children, icon }) => {
  return (
    <Card className="overflow-hidden w-100" style={{ minWidth: '12rem' }}>
      <Background image={getImage(color)} className="bg-card" />
      <Card.Body className="position-relative">
        <h6>
          {title}
          <span className={`badge badge-soft-${color} rounded-capsule ml-2`}>
            {rate}
          </span>
        </h6>
        <div className={getContentClassNames(color)}>{children}</div>
        <a className="font-weight-semi-bold fs--1 text-nowrap" href={to}>
          {linkText}
          <FontAwesomeIcon
            // icon="angle-right"
            icon={icon}
            transform="down-1.5"
            className="ml-1"
          />
        </a>
      </Card.Body>
    </Card>
  );
};

CardSummary.propTypes = {
  title: PropTypes.string.isRequired,
  rate: PropTypes.string,
  linkText: PropTypes.string,
  to: PropTypes.string,
  color: PropTypes.string,
  children: PropTypes.node,
  icon: PropTypes.string
};

CardSummary.defaultProps = {
  linkText: 'See all',
  to: '#!',
  color: 'primary',
  icon: 'angle-right',
  rate: ''
};

export default CardSummary;
