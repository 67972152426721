export default [
  { title: 'Automotivo', to: '#!' },
  { title: 'E-commerce', to: '#!' },
  { title: 'Cargas Emergenciais', to: '#!' },
  { title: 'Cargas MOPP', to: '#!' },
  { title: 'Carretas blidadas', to: '#!' },
  { title: 'Circuitos', to: '#!' },
  { title: 'Container', to: '#!' },
  { title: 'Crossdocking', to: '#!' },
  { title: 'Full Truck Load (FTL)', to: '#!' },
  { title: 'Line Haul (LH)', to: '#!' },
  { title: 'Milk Run', to: '#!' }
];
