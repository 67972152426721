import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Button } from 'react-bootstrap';
import Logo from 'components/common/Logo';
import { useNavigate } from 'react-router-dom';

import Section from 'components/common/Section';

const ErrorLayout = () => {
  const navigate = useNavigate();
  return (
    <Section className="py-0">
      <Row className="flex-center min-vh-100 py-6">
        <Col sm={12}>
          <Logo />
          <p className="text-center">
            Aguarde! Em breve teremos novidades aqui!
          </p>
          <Button
            onClick={() => {
              navigate(-1);
            }}
            className="btn btn-outline-facebook text-white"
          >
            Voltar
          </Button>
        </Col>
      </Row>
    </Section>
  );
};

ErrorLayout.propTypes = {
  match: PropTypes.object
};

export default ErrorLayout;
