import React from 'react';
import Section from 'components/common/Section';
import { Col, Row } from 'react-bootstrap';
import TypeAnimation from 'react-type-animation';

const Partners = () => {
  return (
    <Section bg="light" className="py-3 shadow-sm">
      <Row className="flex-center">
        <Col className="text-center">
          <h4>
            Na Axon sua empresa pode contar com uma equipe de multidisciplinar
            de profissionais
            <TypeAnimation
              sequence={[
                ' qualificados',
                1500,
                ' éticos',
                1500,
                ' experientes',
                1500
              ]}
              repeat={Infinity}
              wrapper="span"
              className="fw-bold"
            />
            com vasta atuação no mercado
          </h4>
          <h4>logístico e de tecnologia da informação.</h4>
        </Col>
      </Row>
    </Section>
  );
};

export default Partners;
