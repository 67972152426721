import { configureStore } from '@reduxjs/toolkit';
import filterReducer from '../slices/filters';
import confMapaReducer from '../slices/confMapa';

export default configureStore({
  reducer: {
    filters: filterReducer,
    configMapa: confMapaReducer
  }
});
