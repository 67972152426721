import React, { useState, useEffect } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Button, Input, InputGroup } from 'reactstrap';
import PageHeader from '../../../components/pages/pageHeader';
import './styleAdicionais.css';
import axios from 'axios';
import { baseUrl } from '../../../config';
import SmallCard from '../../../components/kpis/SmallCard';
import LoadCarregamento from '../../../components/load-carregamento';
import moment from 'moment/moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TipoMotoristas from '../../../components/kpis/TipoMotorista';
import OcorrenciasChartBar from '../../../components/kpis/ocorrenciasChart';
import ChartPie from '../../../components/kpis/OcorrenciasChartPie';
import EvaGracinha from '../../eva/eva-gracinha';

import '../../eva/styleEva.css';

const AdicionaisKPI = () => {
  const [data, setData] = useState([]);
  const [load, setLoad] = useState(true);
  const [reload, setReload] = useState(false);
  // eslint-disable-next-line
  const [mes, setMes] = useState(moment().format('MM'));
  const [dataInicial, setDataInicial] = useState(
    moment().format(`YYYY-${mes}-01`)
  );
  const [dataFinal, setDataFinal] = useState(moment().format(`YYYY-MM-DD`));
  const idGrupo = JSON.parse(sessionStorage.getItem('user'));

  const sliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1
  };

  const getData = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${sessionStorage.token}`
      }
    };

    axios
      .get(
        `${baseUrl}/kpis/adicionais/${idGrupo.id_cliente}/${dataInicial}/${dataFinal}`,
        config
      )
      .then(response => {
        setData(response.data);
        setLoad(false);
        setReload(false);
      })
      .catch(error => {
        console.log(`Erro ${error}`);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <PageHeader
        title="Indicadores Adicionais"
        description=" "
        // eslint-disable-next-line
        children={
          <Row>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <InputGroup size="sm">
                <Input
                  type="date"
                  name="data inicial"
                  defaultValue={dataInicial}
                  onChange={e => {
                    setDataInicial(e.target.value);
                  }}
                  className="custom-input"
                />
                <Input
                  type="date"
                  name="data final"
                  defaultValue={dataFinal}
                  onChange={e => {
                    setDataFinal(e.target.value);
                  }}
                  className="custom-input"
                />
                <Button
                  onClick={() => {
                    setReload(true);
                    getData();
                  }}
                >
                  Buscar
                </Button>
              </InputGroup>
            </Col>
            {reload && (
              <Col sm={10} md={1} lg={1}>
                <div className="loader">
                  <span className="loader__element">●</span>
                  <span className="loader__element">●</span>
                  <span className="loader__element">●</span>
                </div>
              </Col>
            )}
          </Row>
        }
      />
      {load ? (
        <>
          <LoadCarregamento
            msg1Line={'Aguarde o carregamento dos dados.'}
            msg2Line={'Isso pode demorar um pouco.'}
          />
        </>
      ) : (
        <>
          <EvaGracinha />
          <Row className="pt-3">
            <Col md={3}>
              <TipoMotoristas
                data={data.totalTipoMotorista}
                radius={['100%', '80%']}
              />
            </Col>
            <Col sm={12} md={9} lg={9}>
              <Card>
                <Card.Body>
                  <Row>
                    <Col
                      xs={12}
                      md={3}
                      lg={3}
                      className="border-200 border-bottom p-3 border-end"
                    >
                      <h6 className="pb-1 text-700">Emissao CO²</h6>
                      <p className="font-sans-serif text-warning lh-1 mb-1 fs-2">
                        {data.emissaoCarbono.emissaoTotal}
                        {' ton.'}
                      </p>
                      <div className="d-flex align-items-center">
                        <h6 className="fs--1 text-500 mb-0">
                          Emissão total de carbono
                        </h6>
                      </div>
                    </Col>
                    <Col
                      xs={12}
                      md={3}
                      lg={3}
                      className="border-200 border-bottom p-3 border-end"
                    >
                      <h6 className="pb-1 text-700">Emissao CO² Biogenico</h6>
                      <p className="font-sans-serif text-success lh-1 fs-2">
                        {data.emissaoCarbono.emissaoCO2biogenico}
                        {' ton.'}
                      </p>
                      <div className="d-flex align-items-center">
                        <h6 className="fs--1 text-500">{'  '}</h6>
                      </div>
                    </Col>
                    <Col
                      xs={12}
                      md={3}
                      lg={3}
                      className="border-200 border-bottom p-3"
                    >
                      <h6 className="pb-1 text-700">Qtd. Acidente</h6>
                      <p className="font-sans-serif text-info lh-1 mb-1 fs-2">
                        {Number(data.quantidadeAcidente).toLocaleString(
                          'pt-BR',
                          {
                            maximumFractionDigits: 2
                          }
                        )}
                      </p>
                      <div className="d-flex align-items-center">
                        <h6 className="fs--1 text-500 mb-0">
                          Acumulado do período
                        </h6>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      xs={12}
                      md={3}
                      lg={3}
                      className="border-200 p-3 border-end"
                    >
                      <h6 className="pb-1 text-700">Valor Frete all-in</h6>
                      <p className="font-sans-serif text-info lh-1 mb-1 fs-2">
                        R${' '}
                        {Number(data.valorFrete).toLocaleString('pt-BR', {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2
                        })}
                      </p>
                      <div className="d-flex align-items-center">
                        <h6 className="fs--1 text-500 mb-0">
                          Acumulado do período
                        </h6>
                      </div>
                    </Col>
                    <Col
                      xs={12}
                      md={3}
                      lg={3}
                      className="border-200 p-3 border-end"
                    >
                      <h6 className="pb-1 text-700">Km percorrido</h6>
                      <p className="font-sans-serif text-info lh-1 mb-1 fs-2">
                        {data.kmmPercorrido} km
                      </p>
                      <div className="d-flex align-items-center">
                        <h6 className="fs--1 text-500 mb-0">
                          Acumulado do período
                        </h6>
                      </div>
                    </Col>
                    <Col xs={12} md={3} lg={3} className="border-200 p-3">
                      <h6 className="pb-1 text-700">Frete por km percorrido</h6>
                      <p className="font-sans-serif text-info lh-1 mb-1 fs-2">
                        R${' '}
                        {Number(data.fretePorKmm).toLocaleString('pt-BR', {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2
                        })}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      xs={12}
                      md={3}
                      lg={3}
                      className="border-200 p-3 border-end"
                    >
                      <h6 className="pb-1 text-700">Total Transportado</h6>
                      <p className="font-sans-serif text-info lh-1 mb-1 fs-2">
                        R${' '}
                        {Number(data.valorCarregado).toLocaleString('pt-BR', {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2
                        })}
                      </p>
                      <div className="d-flex align-items-center">
                        <h6 className="fs--1 text-500 mb-0">
                          Acumulado do período
                        </h6>
                      </div>
                    </Col>
                    <Col
                      xs={12}
                      md={3}
                      lg={3}
                      className="border-200 p-3 border-end"
                    >
                      <h6 className="pb-1 text-700">
                        Frete sobre total transportado
                      </h6>
                      <p className="font-sans-serif text-info lh-1 mb-1 fs-2">
                        {data.percentualFrete} %
                      </p>
                      <div className="d-flex align-items-center">
                        <h6 className="fs--1 text-500 mb-0">
                          Acumulado do período
                        </h6>
                      </div>
                    </Col>
                    <Col xs={12} md={3} lg={3} className="border-200 p-3">
                      <h6 className="pb-1 text-700">{'Ocorrências'}</h6>

                      <p className="font-sans-serif text-info lh-1 mb-1 fs-2">
                        {data.quantidadeOcorrencia.total.geral}
                      </p>
                      <a
                        className="font-weight-semi-bold fs--1 text-nowrap"
                        href={`${baseUrl}/kpis/excel/ocorrencia/${idGrupo.id_cliente}/${dataInicial}/${dataFinal}`}
                      >
                        <FontAwesomeIcon
                          icon="cloud-download-alt"
                          transform="down-1.5"
                          className="ml-1"
                        />
                      </a>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          {data.quantidadeOcorrencia.ocorrencias.length > 0 && (
            <Row className="pt-3">
              <Col>
                <Card>
                  <Row>
                    <Col>
                      <h5 className="p-3 pb-0">
                        {data.quantidadeOcorrencia.total.geral}
                        {'  '}Ocorrencias
                      </h5>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12} md={6} lg={6}>
                      <OcorrenciasChartBar dados={data.quantidadeOcorrencia} />
                    </Col>
                    <Col sm={12} md={6} lg={3} className="p-3">
                      <ChartPie dados={data.quantidadeOcorrencia} />
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          )}
        </>
      )}
    </>
  );
};

export default AdicionaisKPI;
