import styled from 'styled-components';

export const FigureBG = styled.figure`
  width: 100%;
  height: 6rem;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 0%;
`;
export const ImageBG = styled.img`
  width: 500px;
  height: auto;
`;
export const LinkRealTime = styled.a`
  cursor: pointer;
  z-index: 999999999999;
  font-style: normal;
  color: #c6c6c6;
  &:hover {
    text-decoration: none;
    color: #003c5e;
  }
`;
