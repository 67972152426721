import React, { useState, useEffect } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Button, Input, InputGroup } from 'reactstrap';
import PageHeader from '../../../components/pages/pageHeader';
import './styleAdicionais.css';
import moment from 'moment/moment';

import '../../eva/styleEva.css';

const RelatorioViagens = () => {
  const [dataInicial, setDataInicial] = useState(moment().format(`YYYY-MM-01`));
  const [dataFinal, setDataFinal] = useState(moment().format(`YYYY-MM-DD`));

  const sliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1
  };

  const usuario = JSON.parse(sessionStorage.getItem('user'));

  return (
    <>
      <PageHeader
        title="Relatório de viagens"
        description=" "
        // eslint-disable-next-line
        children={
          <Row>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <InputGroup size="sm">
                <Input
                  type="date"
                  name="data_inicial"
                  defaultValue={dataInicial}
                  onChange={e => {
                    setDataInicial(e.target.value);
                  }}
                  className="custom-input"
                />
                <Input
                  type="date"
                  name="data_final"
                  defaultValue={dataFinal}
                  onChange={e => {
                    setDataFinal(e.target.value);
                  }}
                  className="custom-input"
                />
                <Button
                  onClick={() => {
                    window.open(
                      `https://api.axon.log.br/report/viagens/${new Date(
                        dataInicial
                      ).toISOString()}/${new Date(dataFinal).toISOString()}/${
                        usuario.id_cliente
                      }`,
                      'RELATORIO',
                      'location=no,width=800,height=600,top=10,left=10'
                    );
                  }}
                >
                  Buscar
                </Button>
              </InputGroup>
            </Col>
          </Row>
        }
      />
    </>
  );
};

export default RelatorioViagens;
