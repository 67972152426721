import Swal from 'sweetalert2';

const AlertErrorSaveData = text => {
  Swal.fire({
    title: 'Não foi possível salvar os dados',
    text: text,
    icon: 'Error',
    footer: 'Se o erro persistir contate o suporte!',
    cancelButtonText: 'Ok',
    reverseButtons: true,
    confirmButtonColor: '#2b7be5'
  });
};
export default AlertErrorSaveData;
