import React, { useContext, useEffect, useCallback } from 'react';
import { Outlet, useLocation, Navigate } from 'react-router-dom';
import AppContext from 'context/Context';
import { useAuth } from '../hooks/AuthContext';

const MapLayout = () => {
  const { hash, pathname } = useLocation();
  const {
    config: { isDark, isRTL },
    setConfig
  } = useContext(AppContext);

  const { user } = useAuth();

  if (!user) {
    return <Navigate to="/login" />;
  }

  return (
    <>
      <div style={{ height: '100vh', width: '100%' }}>
        <Outlet />
      </div>
    </>
  );
};

export default MapLayout;
