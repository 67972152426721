export const version = process.env.REACT_APP_VERSION;
export const navbarBreakPoint = 'xl'; // Vertical navbar breakpoint
export const topNavbarBreakpoint = 'lg';
export const settings = {
  isFluid: true,
  isRTL: false,
  isDark: true,
  navbarPosition: 'vertical',
  showBurgerMenu: false, // controls showing vertical nav on mobile
  currency: '$',
  isNavbarVerticalCollapsed: false, // toggle vertical navbar collapse
  navbarStyle: 'card'
};

const LOCAL_DOMAINS = [
  'localhost',
  '127.0.0.1',
  '192.168.15.9',
  '10.1.2.49',
  '192.168.1.125'
];

export const baseUrl = LOCAL_DOMAINS.includes(window.location.hostname)
  ? 'http://127.0.0.1:8000/api'
  : 'https://api.axon.log.br/api';

export const baseUrlHub = LOCAL_DOMAINS.includes(window.location.hostname)
  ? 'http://127.0.0.1:8001/api'
  : 'https://escape79-backend-php-apihub.rj.r.appspot.com/api';

export default { version, navbarBreakPoint, topNavbarBreakpoint, settings };
