import React, { useContext, useEffect, useRef, useState } from 'react';
import { Accordion, Card, Col, Row, ProgressBar } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './map.css';
import { useSelector } from 'react-redux';
import { CardBody, CardFooter, Label } from 'reactstrap';
import { dateTimeEnToBr } from '../../../utils';
import { Icon } from '@iconify/react';

const ViagensBarMap = props => {
  const filters = useSelector(state => state.filters);
  const { data, atualizadoEm } = props;

  return (
    <Accordion id="accordionConfig" className="border rounded bg-transparent">
      <Accordion.Item eventKey="0" className="border-0">
        <Card.Header className="p-0" id={`AccordionHeading0`}>
          <Accordion.Button className="btn btn-link text-decoration-none d-block w-100 py-2 px-3 border-0 rounded-0 text-start shadow-none">
            <FontAwesomeIcon
              icon="caret-right"
              className="accordion-icon me-3 "
              transform="shrink-2"
            />
            <span className="fw-medium font-sans-serif text-900">{`Viagens - Atualizado em ${atualizadoEm}`}</span>
          </Accordion.Button>
        </Card.Header>
        <Accordion.Body className="p-0">
          <Card.Body
            as="div"
            style={{ minHeight: '800px', overflow: 'auto' }}
            className="custom-scrollbar"
          >
            {data.features?.length <= 0
              ? 'Carregando...'
              : data.features?.map((i, k) => {
                  return (
                    <Card key={k} className="mb-2">
                      <CardBody>
                        <Row>
                          <Col sm={12} md={12} lg={12}>
                            <span className="text-primary">
                              {`${i.properties.num_romaneio} - ${i.properties.status}`}
                            </span>
                          </Col>
                          <Col>
                            {i.properties.status === 'Viagem' && (
                              <ProgressBar
                                striped
                                variant="info"
                                now={i.properties.nac_percentual_executado_rota}
                                label={`${i.properties.nac_percentual_executado_rota}%`}
                              />
                            )}
                          </Col>
                          <Col sm={12} md={12} lg={12}>
                            <span className="text-info">
                              <Icon icon="ph:buildings-duotone" />
                              {i.properties.destinatario}
                            </span>
                          </Col>
                          <Col sm={12} md={12} lg={12}>
                            <span className="text-">
                              <Icon icon="ph:person" />
                              {i.properties.nome_motorista}
                            </span>
                          </Col>
                          <Col sm={12} md={12} lg={12}>
                            <Icon icon="ph:calendar-blank-light" />{' '}
                            {dateTimeEnToBr(i.properties.data_ultima_posicao)}
                          </Col>
                          <Col sm={12} md={12} lg={12}>
                            <Icon icon="ph:air-traffic-control-light" />{' '}
                            {`${i.properties.distancia_destino} km ~${i.properties.tempo_faltante_aproximado}`}
                          </Col>
                          <Col sm={12} md={12} lg={12}>
                            <Icon icon="ph:truck-light" />{' '}
                            {i.properties.placa_controle} {` / `}
                            {i.properties.placa_referencia}
                          </Col>
                          <Col sm={12} md={12} lg={12}>
                            Nota(s) fiscal(is): {i.properties.nfes.notas}
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  );
                })}
          </Card.Body>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default ViagensBarMap;
