import axios from 'axios';
export default axios.create({
  baseURL: 'https://api.axon.log.br/api/',
  //baseURL: 'http://127.0.0.1:8000/api/',
  //baseURL: 'http://localhost/api/',
  //baseURL: 'http://192.168.1.121:8000/api/',
  //baseURL: 'https://api-cct-clusters.axon.log.br/api/',
  headers: {
    Accept: 'application/json'
  }
});
