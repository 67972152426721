import React from 'react';

const func = () => {
  return 'Compartilhar';
};
export const dashboardRoutes = {
  label: 'Dashboard',
  labelDisable: true,
  roles: ['dev', 'admin', 'viewer', 'track'],
  children: [
    {
      name: 'Dashboard',
      active: true,
      icon: 'chart-pie',
      roles: ['dev', 'admin', 'viewer', 'tower'],
      children: [
        {
          name: 'Viagens',
          to: '/dashboard/viagens',
          active: true,
          roles: ['dev', 'admin', 'viewer', 'tower']
        },
        {
          name: 'Cargas',
          to: '/dashboard/cargas',
          active: true,
          roles: ['dev', 'admin', 'viewer', 'tower']
        },
        {
          name: 'Tracking',
          to: '/dashboard/tracking',
          active: true,
          roles: ['dev', 'track']
        }
      ]
    }
  ]
};

export const monitoramentoRoutes = {
  label: 'Monitoramento',
  roles: ['dev', 'admin', 'viewer'],
  children: [
    {
      name: 'Real-time',
      icon: 'calendar-alt',
      to: '/monitoramento/real-time',
      active: true,
      roles: ['dev', 'admin', 'viewer', 'tower']
    },
    {
      name: 'Real-time master',
      icon: 'calendar-alt',
      to: '/monitoramento/real-time-master',
      active: true,
      roles: ['dev', 'admin', 'tower']
    },
    {
      name: 'Histórico',
      icon: ['fab', 'trello'],
      to: '/monitoramento/historico',
      active: true,
      roles: ['dev', 'admin', 'viewer', 'tower']
    }
  ]
};

export const appRoutes = {
  label: 'Axon+',
  roles: ['admin', 'viewer'],
  children: [
    {
      name: 'Calendário',
      icon: 'calendar-alt',
      to: '/app/calendar',
      active: true,
      roles: ['dev', 'admin', 'viewer', 'tower']
    },
    {
      name: 'Solicitações',
      icon: ['fab', 'trello'],
      to: '/app/solicitacoes',
      active: true,
      roles: ['dev', 'admin', 'viewer', 'tower']
    },
    {
      name: 'Compartilhar',
      icon: ['fab', 'trello'],
      to: '/app/compartilhar',
      active: true,
      roles: ['dev', 'admin', 'viewer', 'tower']
    }
  ]
};

export const kpisRoutes = {
  label: 'KPIs',
  roles: ['dev', 'admin', 'viewer'],
  children: [
    {
      name: 'Carregamento',
      icon: 'file-alt',
      to: '/kpis/carregamento',
      active: true,
      roles: ['dev', 'admin', 'viewer', 'tower']
    },
    {
      name: 'Descarga',
      icon: 'file-alt',
      to: '/kpis/descarga',
      active: true,
      roles: ['dev', 'admin', 'viewer', 'tower']
    },
    {
      name: 'Adicionais',
      icon: 'file-alt',
      to: '/kpis/adicionais',
      active: true,
      roles: ['dev', 'admin', 'viewer', 'tower']
    },
    {
      name: 'Containers',
      icon: 'file-alt',
      to: '/kpis/containers',
      active: true,
      roles: ['dev', 'admin', 'viewer', 'tower']
    },
    {
      name: 'Fretes 🆕',
      active: true,
      roles: ['dev', 'admin', 'viewer', 'tower'],
      icon: 'file-alt',
      children: [
        {
          name: 'Exportação',
          to: '/kpis/exportFretes',
          active: true,
          roles: ['dev', 'admin', 'viewer', 'tower']
        }
      ]
    }
  ]
};

export const landingRoutes = {
  label: 'Relatórios',
  roles: ['dev', 'admin', 'viewer'],
  children: [
    {
      name: 'Exportar PDF',
      active: true,
      roles: ['dev', 'admin', 'viewer', 'tower'],
      icon: 'file-alt',
      children: [
        {
          name: 'Carregamento',
          to: '/forms/floating-labels',
          active: true,
          roles: ['dev', 'admin', 'viewer', 'tower']
        },
        {
          name: 'Descarga',
          to: '/forms/wizard',
          active: true,
          roles: ['dev', 'admin', 'viewer', 'tower']
        },
        {
          name: 'Adicionais',
          to: '/forms/wizard',
          active: true,
          roles: ['dev', 'admin', 'viewer', 'tower']
        }
      ]
    },
    {
      name: 'Viagens (CTEs)',
      active: true,
      roles: ['dev', 'admin', 'viewer', 'tower'],
      icon: 'file-alt',
      children: [
        {
          name: 'Excel',
          to: '/relatorio/viagens',
          active: true,
          roles: ['dev', 'admin', 'viewer', 'tower']
        }
      ]
    },
    {
      name: 'Docs. Digitalizados',
      active: true,
      roles: ['dev', 'admin', 'viewer', 'tower'],
      icon: 'file-alt',
      children: [
        {
          name: 'Visualização',
          to: '/relatorio/documentos/digitalizados',
          active: true,
          roles: ['dev', 'admin', 'viewer', 'tower']
        }
      ]
    }
  ]
};

export const landingRoutesContato = {
  label: 'Contato',
  roles: ['dev', 'admin', 'viewer'],
  children: [
    {
      name: 'Telefones',
      active: true,
      roles: ['dev', 'admin', 'viewer', 'tower'],
      icon: 'file-alt',
      children: [
        {
          name: 'Ligue-me',
          to: '/forms/floating-labels',
          active: true,
          roles: ['dev', 'admin', 'viewer', 'tower']
        },
        {
          name: 'WhatsApp',
          to: '/forms/wizard',
          active: true,
          roles: ['dev', 'admin', 'viewer', 'tower']
        },
        {
          name: 'AxonBOT',
          to: '/forms/wizard',
          active: true,
          roles: ['dev', 'admin', 'viewer', 'tower']
        }
      ]
    }
  ]
};

export const torreRoutes = {
  label: 'Torre',
  roles: ['dev', 'admin', 'viewer'],
  children: [
    {
      //velocidade,desvio de rota, parado em local proibido,SM
      name: 'Alertas',
      icon: 'calendar-alt',
      to: '/alertas/gerais',
      active: true,
      roles: ['dev', 'admin', 'tower']
    },
    {
      //KM,produtividade,ida/volta vazio,oportunidades
      name: 'Indicadores',
      icon: 'calendar-alt',
      to: '/alertas/indicadores',
      active: true,
      roles: ['dev', 'admin', 'tower']
    }
  ]
};

export default [
  dashboardRoutes,
  appRoutes,
  kpisRoutes,
  monitoramentoRoutes,
  landingRoutes,
  landingRoutesContato,
  torreRoutes
];
