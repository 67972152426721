import React from 'react';
import PropTypes from 'prop-types';
import * as echarts from 'echarts/core';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { LineChart } from 'echarts/charts';
import {
  GridComponent,
  TooltipComponent,
  TitleComponent,
  LegendComponent
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';

// eslint-disable-next-line no-unused-vars
import { getColor, getPastDates, rgbaColor } from 'helpers/utils';
import { tooltipFormatter } from 'helpers/echart-utils';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  CanvasRenderer,
  LegendComponent
]);

const getOptions = (periodo1, periodo2, periodo3, periodo4, label) => ({
  color: [getColor('primary'), getColor('success'), getColor('info')],
  tooltip: {
    trigger: 'axis',
    padding: [7, 10],
    backgroundColor: getColor('100'),
    borderColor: getColor('300'),
    textStyle: { color: getColor('dark') },
    borderWidth: 1,
    transitionDuration: 0,
    formatter: tooltipFormatter
  },
  xAxis: {
    type: 'category',
    data: label,
    boundaryGap: false,
    silent: true,
    axisPointer: {
      lineStyle: {
        color: getColor('300')
      }
    },
    splitLine: { show: false },
    axisLine: {
      lineStyle: {
        color: getColor('300')
      }
    },
    axisTick: {
      show: true,
      lineStyle: {
        color: getColor('200')
      },

      interval: 5
    }
  },
  yAxis: {
    type: 'value',
    position: 'right',
    axisPointer: { show: false },
    splitLine: {
      lineStyle: {
        color: getColor('200')
      }
    },
    axisLabel: {
      show: true,
      color: getColor('600')
    },
    axisTick: { show: false },
    axisLine: { show: false }
  },
  series: [
    {
      type: 'line',
      name: '1º Semana',
      data: periodo1,
      showSymbol: false,
      symbol: 'circle',
      lineStyle: { color: getColor('primary') },
      itemStyle: {
        borderColor: getColor('primary'),
        borderWidth: 2,
        backgroundColor: 'transparent'
      },
      symbolSize: 2,
      areaStyle: {
        color: {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: rgbaColor(getColor('primary'), 0.2)
            },
            {
              offset: 1,
              color: rgbaColor(getColor('primary'), 0)
            }
          ]
        }
      }
    },

    {
      type: 'line',
      name: '2º Semana',
      data: periodo2,
      showSymbol: false,
      symbol: 'circle',
      itemStyle: {
        borderColor: getColor('success'),
        borderWidth: 2
      },
      lineStyle: {
        color: getColor('success')
      },
      symbolSize: 2,
      areaStyle: {
        color: {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: rgbaColor(getColor('success'), 0.2)
            },
            {
              offset: 1,
              color: rgbaColor(getColor('success'), 0)
            }
          ]
        }
      }
    },
    {
      type: 'line',
      name: '3º Semana',
      data: periodo3,
      showSymbol: false,
      symbol: 'circle',
      itemStyle: {
        borderColor: getColor('info'),
        borderWidth: 2
      },
      lineStyle: {
        color: getColor('info')
      },
      symbolSize: 2,
      areaStyle: {
        color: {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: rgbaColor(getColor('info'), 0.2)
            },
            {
              offset: 1,
              color: rgbaColor(getColor('info'), 0)
            }
          ]
        }
      }
    },
    {
      type: 'line',
      name: '4º Semana',
      data: periodo4,
      showSymbol: false,
      symbol: 'circle',
      itemStyle: {
        borderColor: getColor('warning'),
        borderWidth: 2
      },
      lineStyle: {
        color: getColor('warning')
      },
      symbolSize: 2,
      areaStyle: {
        color: {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: rgbaColor(getColor('warning'), 0.2)
            },
            {
              offset: 1,
              color: rgbaColor(getColor('warning'), 0)
            }
          ]
        }
      }
    }
  ],
  grid: {
    containLabel: true,
    right: '5px',
    left: 0,
    bottom: 0,
    top: 10
  }
});

const ComparativoSemanalGrafico = ({
  periodo1,
  periodo2,
  periodo3,
  periodo4,
  label,
  load
}) => {
  return (
    <ReactEChartsCore
      showLoading={load}
      echarts={echarts}
      option={getOptions(periodo1, periodo2, periodo3, periodo4, label)}
      style={{ height: '16.25rem' }}
    />
  );
};

ComparativoSemanalGrafico.propTypes = {
  periodo1: PropTypes.array,
  periodo2: PropTypes.array,
  periodo3: PropTypes.array,
  periodo4: PropTypes.array,
  load: PropTypes.bool,
  label: PropTypes.array
};

export default ComparativoSemanalGrafico;
