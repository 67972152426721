import { getColor, rgbaColor, tooltipFormatter } from 'helpers/utils';
import React, { useContext, useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import axios from 'axios';
import { baseUrl } from 'config';
import * as echarts from 'echarts/core';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { Spinner } from 'react-bootstrap';

const Grafico = ({ agrupador }) => {
  const [load, setLoad] = useState(false); //Mudar para true
  const [resData, setResData] = useState([]);
  const [total, setTotal] = useState([]);
  // const [agrupador, setAgrupado] = useState([]);
  const [topDez, setTopDez] = useState([]);
  const [tipoColor, setTipoColor] = useState([]);
  const usuario = JSON.parse(sessionStorage.getItem('user'));
  // const getData = () => {
  //   const config = {
  //     headers: {
  //       Authorization: `Bearer ${sessionStorage.token}`
  //     }
  //   };

  //   axios
  //     .get(
  //       `${baseUrl}/kpis/getLoadsTotal?codGrupoNegociador=${usuario.id_grupo_cliente}&dataInicio=${di}&dataFim=${df}`,
  //       config
  //     )
  //     .then(response => {
  //       setResData(response.data);
  //       setTotal(response.data.total);
  //       setAgrupado(response.data.agrupado);
  //       setTopDez(response.data.topDez);
  //     })
  //     .catch(error => {
  //       console.log(`Erro ${error}`);
  //     })
  //     .finally(() => {
  //       setLoad(true);
  //     });
  // };

  function handleName(str) {
    if (str.length > 23) {
      return str.substring(0, 24) + '...';
    } else {
      return str;
    }
  }
  const arrData =
    agrupador.length > 0 ? agrupador.map(({ quantidade }) => quantidade) : [0];
  const arrLabel =
    agrupador.length > 0
      ? agrupador.map(e => handleName(e.nome) + ' ' + e.qtde_percentual + '%')
      : [0];

  const getTipoColors = () => {
    const c = arrData.map((i, k) => {
      return '#f5803e';
    });

    setTipoColor(c);
  };
  useEffect(() => {
    getTipoColors();
  }, []);
  const data = {
    datasets: [
      {
        data: arrData,
        backgroundColor: [
          rgbaColor(getColor('info'), 1),
          rgbaColor(getColor('warning'), 1),
          rgbaColor(getColor('success'), 1)
        ],
        borderWidth: 1,
        borderColor: getColor('100')
      }
    ],
    labels: arrLabel
  };

  const options = {
    maintainAspectRatio: true,
    color: 'white'
  };

  const getOption = () => ({
    tooltip: {
      trigger: 'axis',
      padding: [7, 10],
      backgroundColor: getColor('100'),
      borderColor: getColor('300'),
      textStyle: { color: getColor('dark') },
      borderWidth: 1,
      formatter: tooltipFormatter,
      transitionDuration: 0,
      axisPointer: {
        type: 'none'
      }
    },
    xAxis: {
      type: 'value',
      boundaryGap: '0%',
      axisLine: {
        show: true,
        lineStyle: {
          color: getColor('300')
        }
      },
      axisTick: { show: true },
      axisLabel: {
        color: getColor('500')
      },
      splitLine: {
        show: false
      },
      min: 0
    },
    yAxis: {
      type: 'category',
      data: arrLabel,
      boundaryGap: '0%',
      axisLabel: {
        show: true,
        color: getColor('1000'),
        margin: 15,
        width: 180
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: getColor('200')
        }
      },
      axisTick: { show: false },
      axisLine: {
        lineStyle: {
          color: getColor('300')
        }
      }
    },
    series: [
      {
        type: 'bar',
        name: 'Total',
        seriesLayoutBy: 'column',
        data: arrData,
        color: ['#f5803e'],
        lineStyle: { color: getColor('primary') },
        itemStyle: {
          borderRadius: [0, 3, 3, 0],
          color: '#f5803e'
        },

        label: {
          show: true,
          position: 'right',
          color: getColor('600')
        },
        showSymbol: false,
        symbol: 'circle',
        smooth: false,
        emphasis: {
          scale: true
        }
      }
    ],
    grid: { right: '0%', left: '30%', bottom: '10%', top: '10%' }
  });

  return agrupador.length == 0 ? (
    <></>
  ) : (
    <ReactEChartsCore
      echarts={echarts}
      option={getOption()}
      style={{ height: '18.75rem' }}
    />
  );
};

export default Grafico;

///<Pie data={data} options={options} style={{ height: '10%' }}/>
