import React, { Fragment, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Button, Input, InputGroup } from 'reactstrap';
import PageHeader from '../../../components/pages/pageHeader';
import { baseUrl } from '../../../config';
import LoadCarregamento from '../../../components/load-carregamento';
import moment from 'moment';

const ExportDashFrete = () => {
  const [data, setData] = useState([]);
  const [load, setLoad] = useState(true);
  const [firstClick, setFirstClick] = useState(false);
  const [reload, setReload] = useState(false);
  const [mes] = useState(moment().format('MM'));
  const [dataInicial, setDataInicial] = useState(
    moment().format(`YYYY-${mes}-01`)
  );
  const [dataFinal, setDataFinal] = useState(moment().format(`YYYY-MM-DD`));
  const idGrupo = JSON.parse(sessionStorage.getItem('user'));
  const token = sessionStorage.token;

  return (
    <Fragment>
      <PageHeader
        title="Exportação da Planilha de Fretes"
        description=" "
        // eslint-disable-next-line
        children={
          <Row>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <InputGroup size="sm">
                <Input
                  type="date"
                  name="data inicial"
                  defaultValue={dataInicial}
                  onChange={e => {
                    setDataInicial(e.target.value);
                  }}
                  className="custom-input"
                />
                <Input
                  type="date"
                  name="data final"
                  defaultValue={dataFinal}
                  onChange={e => {
                    setDataFinal(e.target.value);
                  }}
                  className="custom-input"
                />
                <Button
                  onClick={() => {
                    // setReload(true);
                    setFirstClick(true);
                    window.open(
                      `${baseUrl}/kpis/excel/dashboardFretes/${idGrupo.id_cliente}/${dataInicial}/${dataFinal}?token=${token}`,
                      'Planilha Fretes',
                      'location=no,width=800,height=600,top=10,left=10'
                    );
                  }}
                >
                  Buscar
                </Button>
              </InputGroup>
            </Col>
            {reload && (
              <Col sm={10} md={1} lg={1}>
                <div className="loader">
                  <span className="loader__element">●</span>
                  <span className="loader__element">●</span>
                  <span className="loader__element">●</span>
                </div>
              </Col>
            )}
          </Row>
        }
      />
      {!firstClick ? (
        <div
          style={{
            height: '500px',
            width: '100%',
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <h3>Preencha os campo para efetuar o download</h3>
        </div>
      ) : load ? (
        <>
          <LoadCarregamento
            msg1Line={'Aguarde o carregamento dos dados.'}
            msg2Line={'Isso pode demorar um pouco.'}
          />
        </>
      ) : (
        <>
          <Row className="descargaCards">Teste</Row>
        </>
      )}
    </Fragment>
  );
};

export default ExportDashFrete;
