import { createContext, useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from '../context/useLocalStorage';
import api from '../api';
import globals from '../globals';
const AuthContext = createContext();

// eslint-disable-next-line react/prop-types
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage('user', null);
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  // call this function when you want to authenticate the user
  const login = async (email, password) => {
    await api
      .post(globals.loginEndpoint, {
        email,
        password
      })
      .then(res => {
        if (res.status === 200) {
          const token = res.data.data.token;
          sessionStorage.setItem('token', token);
          api.defaults.headers.Authorization = `Bearer ${token}`;
          setUser(res.data.data);
          const role = res.data.data.role;
          switch (role) {
            case 'track':
              return navigate('dashboard/tracking/real-time');
            default:
              return navigate('dashboard/viagens');
          }
        } else {
          setError(true);
        }
      })
      .catch(error => {
        setError(true);
      });
  };
  const loginAdmin = async (email, password, codigo) => {
    await api
      .post(globals.loginAdminEndpoint, {
        email,
        codigo
      })
      .then(res => {
        if (res.status === 200) {
          console.log(res);
        } else {
          setError(true);
        }
      })
      .then(async () => {
        await api
          .post(globals.loginEndpoint, {
            email,
            password
          })
          .then(res => {
            if (res.status === 200) {
              const token = res.data.data.token;
              sessionStorage.setItem('token', token);
              api.defaults.headers.Authorization = `Bearer ${token}`;
              setUser(res.data.data);
              const role = res.data.data.role;
              switch (role) {
                case 'track':
                  return navigate('dashboard/tracking/real-time');
                default:
                  return navigate('dashboard/viagens');
              }
            } else {
              setError(true);
            }
          })
          .catch(error => {
            setError(true);
          });
      })
      .catch(error => {
        setError(true);
      });
  };

  // call this function to sign out logged in user
  const logout = () => {
    setUser(null);
    sessionStorage.removeItem('token');
    api.defaults.headers.Authorization = undefined;
    navigate('/login', { replace: true });
  };

  const value = useMemo(
    () => ({
      user,
      error,
      login,
      loginAdmin,
      logout
    }),
    [user]
  );
  // eslint-disable-next-line react/react-in-jsx-scope
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
