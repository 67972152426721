export function handleBigNumber(valor) {
  const options = {
    significantDigits: 2,
    thousandsSeparator: '.',
    decimalSeparator: ','
  };

  if (typeof valor !== 'number') valor = 0.0;
  valor = valor.toFixed(options.significantDigits);

  const [currency, decimal] = valor.split('.');
  return `${currency.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    options.thousandsSeparator
  )}${options.decimalSeparator}${decimal}`;
}

export const handleNumber = valor =>
  Number(valor).toLocaleString('pt-BR', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
  });
