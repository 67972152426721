import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  filters: {
    placa: null,
    origem: null,
    dataInicial: new Date().toISOString(),
    dataFinal: new Date().toISOString(),
    notaFiscal: null,
    alerta: false
  }
};

const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    addFilter: (state, action) => {
      state.filters.push(action.payload);
    },
    updateFilter: (state, action) => {
      state.filters[action.payload.label] = action.payload.value;
    },
    removeFilter: (state, action) => {
      state.filters = state.filters.filter(
        filter => filter.id !== action.payload
      );
    }
  }
});

export const { addFilter, updateFilter, removeFilter } = filtersSlice.actions;
export default filtersSlice.reducer;
