import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Modal } from 'react-bootstrap';
import AppContext from 'context/Context';
import DatePicker from 'react-datepicker';
import axios from 'axios';
import { baseUrl } from '../../../config';

const AddScheduleModal = ({
  setIsOpenScheduleModal,
  isOpenScheduleModal,
  scheduleStartDate,
  setScheduleStartDate,
  scheduleEndDate,
  setScheduleEndDate
}) => {
  const {
    config: { isDark }
  } = useContext(AppContext);

  const [formData, setFormData] = useState({});

  const handleClose = () => {
    setIsOpenScheduleModal(!isOpenScheduleModal);
  };

  const handleChange = ({ target }) => {
    let name = target.name;
    let value = name === 'allDay' ? target.checked : target.value;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = e => {
    e.preventDefault();

    const config = {
      headers: {
        Authorization: `Bearer ${sessionStorage.token}`
      }
    };

    axios
      .post(
        `${baseUrl}/desk/calendario`,
        {
          id_user: 1,
          id_cliente: 1,
          label: formData.className,
          url_video: formData.url,
          local: formData.location,
          titulo: formData.title,
          descricao: formData.description,
          data_inicio: formData.start,
          data_fim: formData.end
        },
        config
      )
      .then(response => {
        return response.status === 200 && setIsOpenScheduleModal(false);
        // eslint-disable-next-line no-unreachable
        window.unload();
      })
      .catch(error => {
        console.log(`Erro ${error}`);
      });
  };

  useEffect(() => {
    if (isOpenScheduleModal) {
      setFormData({
        ...formData,
        start: scheduleStartDate,
        end: scheduleEndDate
      });
    } else {
      setScheduleStartDate(null);
      setScheduleEndDate(null);
    }
  }, [isOpenScheduleModal, scheduleStartDate, scheduleEndDate]);

  return (
    <Modal
      show={isOpenScheduleModal}
      onHide={handleClose}
      contentClassName="border"
    >
      <Form onSubmit={handleSubmit}>
        <Modal.Header
          closeButton
          closeVariant={isDark ? 'white' : undefined}
          className="bg-light px-card border-bottom-0"
        >
          <Modal.Title as="h5">Adicionar evento</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-card">
          <Form.Group className="mb-3" controlId="titleInput">
            <Form.Label className="fs-0">Titulo</Form.Label>
            <Form.Control
              type="text"
              name="title"
              required
              onChange={e => {
                setFormData({ ...formData, title: e.target.value });
              }}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="startDate">
            <Form.Label className="fs-0">Data inicial</Form.Label>
            <DatePicker
              selected={scheduleStartDate}
              onChange={date => {
                setScheduleStartDate(date);
                setFormData({ ...formData, start: date });
              }}
              className="form-control"
              placeholderText="MM-DD-YYYY H:M"
              dateFormat="MM-dd-yyyy h:mm aa"
              showTimeSelect
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="endDate">
            <Form.Label className="fs-0">Data final</Form.Label>
            <DatePicker
              selected={scheduleEndDate}
              onChange={date => {
                setScheduleEndDate(date);
                setFormData({ ...formData, end: date });
              }}
              className="form-control"
              placeholderText="MM-DD-YYYY H:M"
              dateFormat="MM-dd-yyyy h:mm aa"
              showTimeSelect
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="fs-0">URL</Form.Label>
            <Form.Control
              type="url"
              rows={3}
              name="url"
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="description">
            <Form.Label className="fs-0">Descrição</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="description"
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="label">
            <Form.Label className="fs-0">Label</Form.Label>
            <Form.Select name="className" onChange={handleChange}>
              <option>Nenhuma</option>
              <option value="1">Informativo</option>
              <option value="2">danger</option>
              <option value="3">warning</option>
              <option value="4">sucess</option>
            </Form.Select>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="bg-light px-card border-top-0">
          <Button
            variant="primary"
            type="submit"
            onClick={handleClose}
            className="px-4 mx-0"
          >
            Salvar
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

AddScheduleModal.propTypes = {
  setIsOpenScheduleModal: PropTypes.func.isRequired,
  isOpenScheduleModal: PropTypes.bool.isRequired,
  setInitialEvents: PropTypes.func,
  initialEvents: PropTypes.array,
  scheduleStartDate: PropTypes.instanceOf(Date),
  setScheduleStartDate: PropTypes.func.isRequired,
  scheduleEndDate: PropTypes.instanceOf(Date),
  setScheduleEndDate: PropTypes.func.isRequired
};

export default AddScheduleModal;
