import React, { useState, useEffect, Fragment, useRef } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import {
  Button,
  Form,
  FormGroup,
  InputGroup,
  Label,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  CardHeader
} from 'reactstrap';
import PageHeader from '../../../components/pages/pageHeader';
import './styleContainersGuest.css';
import axios from 'axios';
import { baseUrl } from '../../../config';
import LoadCarregamento from '../../../components/load-carregamento';
import moment from 'moment/moment';
import EvaGracinha from '../../eva/eva-gracinha';
import '../../eva/styleEva.css';
import InglishTipoMotoristas from 'components/kpis/InglishTipoMotorista';
import { handleNumber } from 'components/utilities/HandleNumbers';
import { AiOutlineClockCircle } from 'react-icons/ai';
import { TbTruckDelivery, TbWeight } from 'react-icons/tb';
import { BsTruckFlatbed } from 'react-icons/bs';
import SendPerEmail from 'components/utilities/SendPerEmail';
import { RiMailSendLine } from 'react-icons/ri';
import IconTruckSvg from '../../../assets/img/illustrations/dataTruckSvg.svg';
import AlertErrorGetData from 'components/alert/errorGetData';
import { useParams } from 'react-router-dom';
import logoAxon from '../../../assets/img/illustrations/logo-axon-registredMark (Branca).svg';
import { getColor } from 'helpers/utils';
import { FaRegHandshake, FaTrailer } from 'react-icons/fa';
import { Bar } from 'react-chartjs-2';
const ContainersKPIGuest = () => {
  const [data, setData] = useState([]);
  const [load, setLoad] = useState(true);
  const [reload, setReload] = useState(false);
  // eslint-disable-next-line
  const [emailField, setEmailField] = useState('');
  const [mes, setMes] = useState(moment().format('MM'));
  const [dataInicial, setDataInicial] = useState(
    moment().format(`YYYY-${mes}-01`)
  );
  const [dataFinal, setDataFinal] = useState(moment().format(`YYYY-MM-DD`));
  const user = JSON.parse(sessionStorage.getItem('user'));
  const { serializerBackData } = useParams();
  const userLanguage = window.navigator.language;

  const config = {
    headers: {
      Authorization: `Bearer ${sessionStorage.token}`
    }
  };
  const getData = () => {
    axios
      .get(`${baseUrl}/kpis/containers?${serializerBackData}`)
      .then(response => {
        setData(response.data);
        setLoad(false);
        setReload(false);
      })
      .catch(error => {
        console.log(`Erro ${error}`);
      });
  };
  const pizzaInfo = (arr, type) => {
    const result = [];
    if (type === 'qtde') {
      arr.map((item, k) => {
        result.push(item.qtde);
      });
    } else if (type === 'incidente') {
      arr.map((item, k) => {
        result.push(item.incidente);
      });
    } else if (type === 'controlavel') {
      arr.map((item, k) => {
        result.push(
          item.controlavel === 0 ? getColor('info') : getColor('warning')
        );
      });
    } else if (type === 'border') {
      arr.map((item, k) => {
        result.push(
          item.controlavel === 0
            ? 'rgba(54, 162, 235, 1)'
            : 'rgba(255, 159, 64, 1)'
        );
      });
    }
    return result;
  };
  useEffect(() => {
    getData();
  }, []);
  ///// MOdal

  return (
    <Fragment>
      <div className="px-5 pt-2">
        <PageHeader
          title="Additional Indicators"
          description=" "
          logo={logoAxon}
          // eslint-disable-next-line
        children={
            <Row className="d-flex align-items-center">
              {reload && (
                <Col sm={12} md={1} lg={1}>
                  <div className="loader">
                    <span className="loader__element">●</span>
                    <span className="loader__element">●</span>
                    <span className="loader__element">●</span>
                  </div>
                </Col>
              )}
              <Col
                sm={12}
                md={5}
                lg={5}
                className="font-sans-serif text-black lh-1 fs-4 d-flex align-items-center"
              >
                <h4 className="fs--1 text-500 mb-0">
                  All data is related to the selected period.
                </h4>
              </Col>
            </Row>
          }
        />
        {load ? (
          <>
            <LoadCarregamento
              msg1Line={'Please wait for the data to load.'}
              msg2Line={'This might take a moment.'}
            />
          </>
        ) : (
          <>
            <EvaGracinha />
            <Row className="pt-3 ">
              <Col sm={12} md={12} lg={12} xl={10}>
                <Card>
                  <Card.Body>
                    {/*   */}
                    <Row>
                      {/* Faturamento */}
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="border-200 p-3 border-end"
                      >
                        <h5 className="pb-1 text-700">
                          Axon{' '}
                          {userLanguage === 'pt-BR' ? 'Faturamento' : 'Revenue'}
                        </h5>
                        <p className="font-sans-serif text-black lh-1 m-3 fs-4 d-flex flex-wrap align-items-end justify-content-end">
                          <b>{handleNumber(data.faturamento_axon)}</b>
                          <small style={{ marginLeft: '1em' }}>{'  '}R$</small>
                        </p>
                        <div className="d-flex align-items-center"></div>
                      </Col>
                      {/* Faturamento do cliente */}
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="border-200 p-3 border-end"
                      >
                        <h5 className="pb-1 text-700">
                          {data.nome_grupo}{' '}
                          {userLanguage === 'pt-BR' ? 'Faturamento' : 'Revenue'}
                        </h5>
                        <p className="font-sans-serif text-black lh-1 m-3 fs-4 d-flex flex-wrap align-items-end justify-content-end">
                          <b className="d-block">
                            {handleNumber(data.faturamento_cliente)}
                          </b>
                          <small style={{ marginLeft: '1em' }}>{'  '}R$</small>
                          {/* <b>52.147,00</b> */}
                        </p>
                      </Col>
                      {/* Total de containers movimentados */}
                      <Col xs={12} md={2} lg={2} className="border-200 p-3 ">
                        <h5 className="pb-1 text-700">
                          {userLanguage === 'pt-BR'
                            ? 'Total containers movimentados'
                            : 'Total containers moved'}{' '}
                        </h5>
                        <p className="font-sans-serif text-info lh-1 m-3 fs-4 d-flex align-items-center justify-content-end">
                          <b>{data.containers_movimentados}</b>
                          {/* <b>19</b> */}
                          <small style={{ marginLeft: '5px' }}>
                            <TbTruckDelivery />
                          </small>
                        </p>
                      </Col>

                      {/* Frota */}
                      <Col
                        xs={12}
                        md={2}
                        lg={2}
                        className="border-200 p-3 border-start "
                      >
                        <h5 className="pb-1 text-700">
                          {userLanguage === 'pt-BR' ? 'Frota' : 'Fleet'}
                        </h5>
                        <p className="font-sans-serif text-info lh-1 m-3 fs-4 d-flex align-items-center justify-content-end">
                          <b> {data.frota} </b>
                          <small style={{ marginLeft: '0.5em' }}>
                            {<FaTrailer />}
                          </small>
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      {/* Tempo médio de operação */}
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="border-200 p-3 border-end"
                      >
                        <h5 className="pb-1 text-700">
                          {' '}
                          {userLanguage === 'pt-BR'
                            ? 'Tempo médio operação'
                            : 'Average processing time'}
                        </h5>
                        <p className="font-sans-serif text-info lh-1 m-3 fs-3 d-flex align-items-center justify-content-end">
                          <b>{data.tempo_viagem}</b>
                          <small style={{ marginRight: '1em' }}>
                            <AiOutlineClockCircle />
                          </small>
                          {/* <b>18:49:13</b> */}
                        </p>
                      </Col>

                      {/* Peso transportado */}
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="border-200 p-3 border-end"
                      >
                        <h5 className="pb-1 text-700">
                          {userLanguage === 'pt-BR'
                            ? 'Peso transportado'
                            : 'Transported weight'}
                        </h5>
                        <p className="font-sans-serif text-info lh-1 m-3 fs-4 d-flex align-items-end justify-content-end">
                          {/* <b> {handleNumber(483615)} </b> */}
                          <b> {data.peso}</b>
                          <small
                            style={{ marginLeft: '0.5em' }}
                            className="d-flex align-items-end"
                          >
                            <TbWeight />
                            {'ton.'}
                          </small>
                        </p>
                      </Col>

                      {/* Devolução vazio OnTime */}
                      <Col
                        xs={12}
                        md={2}
                        lg={2}
                        className="border-200 p-3 border-end"
                      >
                        <h5 className="pb-1 text-700">
                          {userLanguage === 'pt-BR'
                            ? 'Devolução de vazio - On Time'
                            : 'On-time empty return'}{' '}
                        </h5>

                        <p className="font-sans-serif text-info lh-1 m-3 fs-4 d-flex align-items-center justify-content-end">
                          <b>{data.container_on_time}</b>
                          {/* <b>19</b> */}
                          <small style={{ marginLeft: '5px' }}>
                            <BsTruckFlatbed />
                          </small>
                        </p>
                      </Col>
                      {/* Agregado */}
                      <Col xs={12} md={2} lg={2} className="border-200 p-3 ">
                        <h5 className="pb-1 text-700">
                          {userLanguage === 'pt-BR' ? 'Agregado' : 'Contractor'}{' '}
                        </h5>

                        <p className="font-sans-serif text-info lh-1 m-3 fs-4 d-flex align-items-center justify-content-end">
                          <b>{data.agregado}</b>
                          {/* <b>19</b> */}
                          <small style={{ marginLeft: '5px' }}>
                            <FaRegHandshake />
                          </small>
                        </p>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row className="pt-3 d-flex justify-content ">
              <Col sm={12} md={12} lg={12} xl={9}>
                <Card>
                  <CardHeader className="d-flex justify-content-between pt-3">
                    <h3 style={{ color: 'white', fontWeight: 'bold' }}>
                      {userLanguage === 'pt-BR' ? 'Ocorrências' : 'Ocurrences'}
                    </h3>
                    <div
                      style={{ width: '20%' }}
                      className="d-flex flex-column align-items-start"
                    >
                      <div className="d-flex m-2 flex-wrap">
                        <div
                          style={{
                            backgroundColor: getColor('warning'),
                            padding: '0.2rem',
                            paddingLeft: '1rem',
                            paddingRight: '1rem',
                            marginRight: '0.5rem',
                            borderRadius: '5px',
                            color: 'white',
                            fontWeight: 'bold'
                          }}
                        >
                          {data.total_ocorrencias.controlados}
                        </div>
                        <div
                          style={{
                            padding: '0.2rem',
                            marginRight: '0.5rem',
                            borderRadius: '5px',
                            color: 'white',
                            fontWeight: 'bold'
                          }}
                        >
                          {userLanguage === 'pt-BR'
                            ? 'Controlavél'
                            : 'Controllable'}
                        </div>
                      </div>
                      <div className="d-flex m-2 flex-wrap">
                        <div
                          style={{
                            padding: '0.2rem',
                            backgroundColor: getColor('info'),
                            paddingLeft: '1rem',
                            paddingRight: '1rem',
                            marginRight: '0.5rem',
                            borderRadius: '5px',
                            color: 'white',
                            fontWeight: 'bold'
                          }}
                        >
                          {data.total_ocorrencias.nao_controlados}
                        </div>
                        <div
                          style={{
                            padding: '0.2rem',
                            marginRight: '0.5rem',
                            borderRadius: '5px',
                            color: 'white',
                            fontWeight: 'bold'
                          }}
                        >
                          {userLanguage === 'pt-BR'
                            ? 'Não Controlavél'
                            : 'Uncontrollable'}{' '}
                        </div>
                      </div>
                    </div>
                  </CardHeader>
                  <Card.Body className="pt-0">
                    <Bar
                      options={{
                        indexAxis: 'y',
                        responsive: true,
                        // datalabels: [ChartDataLabels],
                        scales: {
                          y: {
                            display: true,
                            ticks: {
                              color: 'white'
                            }
                          },
                          x: {
                            suggestedMin: 0,
                            min: 0,
                            ticks: {
                              color: 'white',
                              stepSize: 1
                            }
                          }
                        },
                        plugins: {
                          decimation: true,
                          legend: {
                            display: false
                          }
                        }
                      }}
                      data={{
                        labels: pizzaInfo(data.ocorrencias, 'incidente'),
                        datasets: [
                          {
                            data: pizzaInfo(data.ocorrencias, 'qtde'),
                            // data: pizzaInfo(data.ocorrencias, 'qtde'),
                            backgroundColor: pizzaInfo(
                              data.ocorrencias,
                              'controlavel'
                            ),
                            borderColor: [
                              pizzaInfo(data.ocorrencias, 'border')
                            ],
                            borderWidth: 1,
                            maxBarThickness: 100
                          }
                        ]
                      }}
                      height={80}
                    />
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </>
        )}
      </div>
    </Fragment>
  );
};

export default ContainersKPIGuest;
