import React, { useContext, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import is from 'is_js';
import MainLayout from './MainLayout';
import SettingsToggle from 'components/settings-panel/SettingsToggle';
import SettingsPanel from 'components/settings-panel/SettingsPanel';
import Landing from 'components/pages/landing/Landing';

import Calendar from 'components/app/calendar/Calendar';
import EventList from 'components/app/events/event-list/EventList';

import ErrorLayout from './ErrorLayout';
import { toast, ToastContainer } from 'react-toastify';
import { CloseButton } from 'components/common/Toast';

import Analytics from 'pages/dashboards/analytics';

import Error404 from 'components/errors/Error404';
import Error500 from 'components/errors/Error500';

import SplitLogin from 'pages/authentication/split/Login';
import SplitLogout from 'pages/authentication/split/Logout';
import SplitLoginAdmin from 'pages/authentication/split/LoginAdmin';
import SplitForgetPassword from 'pages/authentication/split/ForgetPassword';
import SplitPasswordReset from 'pages/authentication/split/PasswordReset';
import SplitLockScreen from 'pages/authentication/split/LockScreen';

import AppContext from 'context/Context';
import Viagens from '../pages/dashboards/viagens';
import CarregamentosKPI from '../pages/kpis/carregamento/carregamento';
import DescargaKPI from '../pages/kpis/descarga/descarga';
import AdicionaisKPI from '../pages/kpis/adicionais/adicionais';
import EmConfiguracaoLayout from './EmConfiguracao';
import { useCurrentPath } from '../utils';
import TowerRealTimePage from '../pages/tower/real-time';
import MapLayout from './MapLayout';
import TowerRealTimeMasterPage from '../pages/tower-master';
import RelatorioViagens from '../pages/relatorio/viagens/adicionais';
import CompartilharPainel from '../pages/compartilhar/add';
import CarrouselGallery from 'components/carousel-gallery';
import DocumentosDigitalizados from 'pages/relatorio/documentos/digitalizados';
import Tracking from 'pages/dashboards/tracking';
import RealTimeTrack from 'pages/dashboards/tracking/real-time-tracking';
import Cargas from 'pages/dashboards/cargas';
import ContainersKPI from 'pages/kpis/containers/containers';
import GuestLayout from './GuestLayout';
import ContainersKPIGuest from 'pages/kpis/containersGuest/containersGuest';
import LoginAdmin from 'pages/authentication/split/LoginAdmin';
import GeneralAlerts from '../pages/tower/alerts/GeneralAlerts';
import IndicatorsAlerts from '../pages/tower/alerts/IndicatorsAlerts';
import SolicitacaoDemandasViagens from '../pages/solicitacao/index';
import ExportDashFrete from 'pages/kpis/exportFretes/exportFretes';

const Layout = props => {
  const HTMLClassList = document.getElementsByTagName('html')[0].classList;
  useContext(AppContext);

  const currentRoute = useCurrentPath();

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
  }, [HTMLClassList]);

  return (
    <>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/login" element={<SplitLogin />} />
        <Route path="/login-admin" element={<SplitLoginAdmin />} />

        <Route element={<ErrorLayout />}>
          <Route path="errors/404" element={<Error404 />} />
          <Route path="errors/500" element={<Error500 />} />
        </Route>
        <Route path="authentication/split/login" element={<SplitLogin />} />
        <Route
          path="authentication/split/login-admin"
          element={<SplitLoginAdmin />}
        />
        <Route path="authentication/split/logout" element={<SplitLogout />} />
        <Route
          path="authentication/split/forgot-password"
          element={<SplitForgetPassword />}
        />
        <Route
          path="authentication/split/reset-password"
          element={<SplitPasswordReset />}
        />
        <Route
          path="authentication/split/lock-screen"
          element={<SplitLockScreen />}
        />

        <Route element={<MainLayout />}>
          <Route path="dashboard/analytics" element={<Analytics />} />
          <Route path="dashboard/viagens" element={<Viagens />} />
          <Route path="dashboard/cargas" element={<Cargas />} />

          <Route path="dashboard/tracking" element={<Tracking />} />

          <Route path="app/calendar" element={<Calendar />} />
          <Route path="app/compartilhar" element={<CompartilharPainel />} />
          <Route path="events/event-list" element={<EventList />} />

          <Route path="kpis/carregamento" element={<CarregamentosKPI />} />
          <Route path="kpis/descarga" element={<DescargaKPI />} />
          <Route path="kpis/adicionais" element={<AdicionaisKPI />} />
          <Route path="kpis/exportFretes" element={<ExportDashFrete />} />
          <Route path="relatorio/viagens" element={<RelatorioViagens />} />
          <Route
            path="relatorio/documentos/digitalizados"
            element={<DocumentosDigitalizados />}
          />
          <Route path="kpis/containers" element={<ContainersKPI />} />
          <Route
            path="app/solicitacoes"
            element={<SolicitacaoDemandasViagens />}
          />
          <Route path="alertas/gerais" element={<GeneralAlerts />} />
          <Route path="alertas/indicadores" element={<IndicatorsAlerts />} />
        </Route>

        <Route element={<GuestLayout />}>
          <Route
            path="kpis/containers/:serializerBackData"
            element={<ContainersKPIGuest />}
          />
        </Route>

        <Route element={<MapLayout />}>
          <Route
            path="monitoramento/real-time"
            element={<TowerRealTimePage />}
          />
          <Route
            path="dashboard/tracking/real-time"
            element={<RealTimeTrack />}
          />
          <Route
            path="monitoramento/real-time-master"
            element={<TowerRealTimeMasterPage />}
          />
          <Route
            path="monitoramento/historico"
            element={<EmConfiguracaoLayout />}
          />
        </Route>
        <Route path="*" element={<Navigate to="/errors/404" replace />} />
      </Routes>
      {currentRoute.pathname !== '/' && <SettingsToggle />}
      {currentRoute.pathname !== '/' && <SettingsPanel />}
      <ToastContainer
        closeButton={CloseButton}
        icon={false}
        position={toast.POSITION.BOTTOM_LEFT}
      />
    </>
  );
};

export default Layout;
