import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';

import mapboxgl from '!mapbox-gl';
import MapboxTraffic from '!@mapbox/mapbox-gl-traffic';
import 'mapbox-gl/dist/mapbox-gl.css';

import axios from 'axios';
import { baseUrl, baseUrlHub } from '../../../config';
import markerImg from '../../../image/custom_marker.png';
import warning from '../../../image/1718387763711.gif';
import { useSelector } from 'react-redux';
import { CardBody, CardHeader, Spinner } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { arredondarParaCima } from '../../../utils';
import som from '../../../sound/sirene.mp3';

mapboxgl.accessToken =
  'pk.eyJ1IjoiYXhvbmFwaSIsImEiOiJjbGRudWE1dGowNHp3M3hudmJ4MTA2YjEwIn0.GkwFZHDIw2A3Le9BuyYa-A';

const GeneralAlerts = () => {
  const mapContainer = useRef(null);
  const map = useRef(null);

  const [lng, setLng] = useState(-58);
  const [lat, setLat] = useState(-16);
  const [pitch, setPitch] = useState(1);
  const [bearing, setBearing] = useState(1);
  const [zoom, setZoom] = useState(4);
  const [isLoading, setIsLoading] = useState(true);

  const [data, setData] = useState([]);
  const [dataVelocidade, setDataVelocidade] = useState([]);

  const [speedError, setSpeedError] = useState(0);

  const imageMarker = new Image();
  imageMarker.src = markerImg;

  const configMapa = useSelector(state => state.configMapa);

  const audioRef = useRef(null);

  const playAudio = () => {
    if (audioRef.current) {
      audioRef.current.play();
    }
  };
  const pauseAudio = () => {
    if (audioRef.current) {
      audioRef.current.pause();
    }
  };

  const axon_markers = {
    type: 'geojson',
    data: `${baseUrl}/alerts/general`
  };

  const trucks_circle = {
    id: 'trucks-circle',
    type: 'symbol',
    source: 'axon_markers',
    layout: {
      'icon-image': 'custom-marker',
      'icon-allow-overlap': true,
      'icon-anchor': 'bottom',
      'icon-size': 1
    },
    paint: {
      'icon-color': [
        'match',
        ['get', 'desvio_rota'],
        0,
        '#3c7a16',
        '#ff9090' // any other store type
      ]
    }
  };

  const trucks_circle_back = {
    id: 'trucks-circle-back',
    type: 'circle',
    source: 'axon_markers',
    layout: {
      visibility: 'visible'
    },
    paint: {
      'circle-radius': {
        stops: [
          [4, 20],
          [16, 120]
        ]
      },
      'circle-opacity': 0.4,
      'circle-pitch-scale': 'viewport',
      'circle-color': [
        'match',
        ['get', 'velocidade'],
        0,
        '#20ec09',
        80,
        '#20ec09',
        '#ffff00'
      ]
    }
  };

  const getData = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${sessionStorage.token}`
      }
    };

    axios
      .get(`${baseUrl}/alerts/general`, config)
      .then(response => {
        setData(response.data);
        map.current.getSource('axon_markers').setData(response.data);
      })
      .catch(error => {
        console.log(`Erro ${error}`);
      })
      .finally(() => {
        setIsLoading(false);
      });

    axios
      .get(`${baseUrlHub}/mix/real-time/speed`, config)
      .then(response => {
        setDataVelocidade(response.data.speeds);
        setSpeedError(response.data.speed_error);
        map.current.getSource('axon_markers').setData(response.data);
      })
      .catch(error => {
        console.log(`Erro ${error}`);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const init = () => {
    if (map.current) return;
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: configMapa.conf.tipo,
      center: [lng, lat],
      zoom: zoom,
      attributionControl: false
    });

    map.current.on('load', () => {
      map.current.addControl(new MapboxTraffic());
      map.current.addControl(new mapboxgl.NavigationControl(), 'top-right');
    });

    map.current.on('move', () => {
      setLng(map.current.getCenter().lng.toFixed(6));
      setLat(map.current.getCenter().lat.toFixed(6));
      setPitch(map.current.getPitch().toFixed(2));
      setBearing(map.current.getBearing().toFixed(2));
      setZoom(map.current.getZoom().toFixed(2));
    });

    map.current.on('styledata', () => {
      if (!map.current.getSource('axon_markers')) {
        map.current.addSource('axon_markers', axon_markers);
        map.current.addImage('custom-marker', imageMarker, { sdf: true });
      }

      if (!map.current.getLayer('trucks-circle-back')) {
        map.current.addLayer(trucks_circle_back);
        map.current.addLayer(trucks_circle);
      }
    });
  };

  useEffect(() => {
    init();
    getData();
  }, []);

  useEffect(() => {
    const interval = setInterval(function () {
      getData();
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (speedError > 0) {
      playAudio();
    } else {
      pauseAudio();
    }
  }, [speedError]);

  return (
    <Fragment>
      <Row>
        <Col sm={12} md={12} lg={3}>
          {isLoading ? (
            <Spinner size="sm" color="primary">{` `}</Spinner>
          ) : (
            <Row>
              {speedError > 0 && (
                <Col sm={12} md={12} lg={12}>
                  <Card>
                    <CardBody className="bg-danger">
                      <img src={warning} height="30" width="30" />
                    </CardBody>
                  </Card>
                </Col>
              )}
              <Col sm={12} md={12} lg={12} className="mb-2">
                <Card>
                  <CardHeader className="text-facebook">
                    Excesso de velocidade / Freadas bruscas ({speedError})
                  </CardHeader>
                  <CardBody>
                    {dataVelocidade.length > 0
                      ? dataVelocidade.map((i, k) => {
                          return (
                            <Card key={k}>
                              <CardBody>
                                <Row key={k}>
                                  <Col
                                    sm={12}
                                    md={12}
                                    lg={5}
                                    className="align-bottom"
                                  >
                                    <h4>{i.plate}</h4>
                                  </Col>
                                  <Col>
                                    <h3
                                      className={
                                        arredondarParaCima(i.speed) >= 80
                                          ? 'text-warning'
                                          : 'text-success'
                                      }
                                    >
                                      {arredondarParaCima(i.speed)}
                                    </h3>
                                  </Col>
                                  <Col>
                                    {arredondarParaCima(i.speed) >= 80 && (
                                      <div>|</div>
                                    )}
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          );
                        })
                      : 'Nenhum veículo acima de 80km/h'}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Col>
        <Col sm={12} md={12} lg={3}>
          {isLoading ? (
            <Spinner size="sm" color="primary">{` `}</Spinner>
          ) : (
            <Row>
              <Col sm={12} md={12} lg={12} className="mb-2">
                <Card>
                  <CardHeader className="text-facebook">
                    Fora rota / Parada não programada
                  </CardHeader>
                  <CardBody>
                    {data.features.map((i, k) => {
                      return (
                        <Card key={k}>
                          <CardBody>
                            <Row key={k}>
                              <Col sm={12} md={12} lg={7}>
                                <h4>{i.properties.placa}</h4>
                              </Col>
                              <Col>
                                {i.properties.parado_errado === 1 ? (
                                  <FontAwesomeIcon
                                    icon="home"
                                    className="text-warning fs-3 me-3"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    icon="home"
                                    className="text-success fs-3 me-3"
                                  />
                                )}
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      );
                    })}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Col>
        <Col sm={12} md={12} lg={6}>
          <div style={{ height: '86vh' }}>
            <div
              ref={mapContainer}
              style={{ height: '86vh', borderRadius: '5px' }}
              className="shadow-lg"
            />
          </div>
          <audio ref={audioRef} src={som} preload="auto" />
        </Col>
      </Row>
    </Fragment>
  );
};

export default GeneralAlerts;
