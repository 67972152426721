import React from 'react';
import Lottie from 'lottie-react';
import truckLoad from '../../assets/img/animated-icons/truckLoad.json';
import Flex from '../common/Flex';

const LoadCarregamento = ({ msg1Line, msg2Line }) => {
  return (
    <Flex
      alignItems="lg-center"
      className="mb-2"
      style={{ height: '60vh', marginTop: '1em', overflow: 'hidden' }}
    >
      <div
        className="p-2 w-100 fs-2 text-primary mb-1"
        style={{ marginLeft: '4em' }}
      >
        {msg1Line}
        <br /> {msg2Line}
      </div>
      <div className="p-2 flex-shrink-1">
        <Lottie
          animationData={truckLoad}
          loop={true}
          style={{ height: '200vh' }}
        />
      </div>
    </Flex>
  );
};

export default LoadCarregamento;
