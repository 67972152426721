import React, { Fragment, useEffect, useState } from 'react';
import CardSummary from '../../../components/kpis/CardSummary';
import { Col, Row } from 'react-bootstrap';
import {
  Badge,
  Button,
  Card,
  CardBody,
  Input,
  InputGroup,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading
} from 'reactstrap';
import Flex from '../../../components/common/Flex';
import './styleDescarga.css';
import PageHeader from '../../../components/pages/pageHeader';
import IndicadorCircular from '../../../components/kpis/IndicadorCircular';
import axios from 'axios';
import { baseUrl } from '../../../config';
import SmallCard from '../../../components/kpis/SmallCard';
import LoadCarregamento from '../../../components/load-carregamento';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EntregasAtrasadas from '../../../components/kpis/EntregasAtrasadas';

const DescargaKPI = () => {
  const [data, setData] = useState([]);
  const [load, setLoad] = useState(true);
  const [reload, setReload] = useState(false);
  const [mes] = useState(moment().format('MM'));
  const [dataInicial, setDataInicial] = useState(
    moment().format(`YYYY-${mes}-01`)
  );
  const [dataFinal, setDataFinal] = useState(moment().format(`YYYY-MM-DD`));
  const idGrupo = JSON.parse(sessionStorage.getItem('user'));

  const getData = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${sessionStorage.token}`
      }
    };

    axios
      .get(
        `${baseUrl}/kpis/descarga/${idGrupo.id_cliente}/${dataInicial}/${dataFinal}`,
        config
      )
      .then(response => {
        setData(response.data);
        setLoad(false);
        setReload(false);
      })
      .catch(error => {
        console.log(`Erro ${error}`);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Fragment>
      <PageHeader
        title="Indicadores de Descarga"
        description=" "
        // eslint-disable-next-line
        children={
          <Row>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <InputGroup size="sm">
                <Input
                  type="date"
                  name="data inicial"
                  defaultValue={dataInicial}
                  onChange={e => {
                    setDataInicial(e.target.value);
                  }}
                  className="custom-input"
                />
                <Input
                  type="date"
                  name="data final"
                  defaultValue={dataFinal}
                  onChange={e => {
                    setDataFinal(e.target.value);
                  }}
                  className="custom-input"
                />
                <Button
                  onClick={() => {
                    setReload(true);
                    getData();
                  }}
                >
                  Buscar
                </Button>
              </InputGroup>
            </Col>
            {reload && (
              <Col sm={10} md={1} lg={1}>
                <div className="loader">
                  <span className="loader__element">●</span>
                  <span className="loader__element">●</span>
                  <span className="loader__element">●</span>
                </div>
              </Col>
            )}
          </Row>
        }
      />
      {load ? (
        <>
          <LoadCarregamento
            msg1Line={'Aguarde o carregamento dos dados.'}
            msg2Line={'Isso pode demorar um pouco.'}
          />
        </>
      ) : (
        <>
          <Row className="descargaCards">
            <Col sm={12} md={4} lg={4} className="descargaCards__child">
              <CardSummary
                // rate="2.354.037,5"
                title="Entregas em andamento no período"
                color="info"
                linkText="ver monitoramento "
                to="../monitoramento/real-time"
                icon="external-link-alt"
              >
                {data.totalEntregaEmAndamento}
              </CardSummary>
            </Col>
            <Col sm={12} md={4} lg={4} className="descargaCards__child">
              <CardSummary
                // rate="06:17:57"
                title="Tempo médio de descarga"
                color="info"
                linkText=""
                to=""
              >
                {data.tempoMedioDesgarga.tempo_medio}
              </CardSummary>
            </Col>
            <Col sm={12} md={4} lg={4} className="descargaCards__child">
              <EntregasAtrasadas
                totalEntregasAtradas={data.totalEntregaAtrasada}
                totalEntregasControlaveis={
                  data.totalEntregaAtrasada - data.carrAtrasadosNaoControlaveis
                }
                totalEntregasNaoControlaveis={data.carrAtrasadosNaoControlaveis}
                data={[
                  {
                    name: 'Controláveis',
                    value:
                      parseInt(data.totalEntregaAtrasada) -
                      parseInt(data.carrAtrasadosNaoControlaveis),
                    color: 'warning'
                  },
                  {
                    name: 'Não controláveis',
                    value: parseInt(data.carrAtrasadosNaoControlaveis),
                    color: 'info'
                  }
                ]}
                radius={['100%', '70%']}
              />
            </Col>
          </Row>
          <Row className="descargaIndicadores">
            <Col sm={12} className="descargaIndicadores__onTimeSmal">
              <CardSummary
                rate={
                  parseInt(data.totalEntregaAtrasada) -
                  parseInt(data.carrAtrasadosNaoControlaveis)
                }
                title="Ontime (Descarga)"
                color="warning"
                linkText="All orders"
              >
                {data.percOntimeDescarga}%
              </CardSummary>
            </Col>
            <Col sm={12} className="descargaIndicadores__pesoSmal">
              <CardSummary
                title="Peso total carregado"
                color="info"
                linkText=""
              >
                {data.pesoCarregado}Kg
              </CardSummary>
            </Col>
            <Col
              sm={12}
              md={12}
              lg={9}
              className="spaceRight_none descargaIndicadores__badActor"
            >
              <Card>
                <CardBody>
                  <Row>
                    <Col sm={12} md={12} lg={6}>
                      <ListGroup>
                        <ListGroupItemHeading>
                          BadActor TOP 5
                        </ListGroupItemHeading>
                        {data.tempoDesgargaBadActorTopFive.length > 0 &&
                          data.tempoDesgargaBadActorTopFive.map(
                            (item, index) => {
                              return (
                                <ListGroupItem
                                  key={index}
                                  style={{ fontSize: 11 }}
                                >
                                  <div>
                                    <Row>
                                      <h6>
                                        <Badge color={'soft-info'} pill>
                                          {' Tempo médio: '}
                                          {item.tempo_medio.substring(
                                            0,
                                            8
                                          )}{' '}
                                        </Badge>{' '}
                                      </h6>
                                    </Row>
                                    <Row>
                                      <h6>
                                        {item.destino + ' '}
                                        <Badge color={'soft-warning'} pill>
                                          {' '}
                                          {item.qtde}{' '}
                                        </Badge>
                                      </h6>
                                    </Row>
                                  </div>
                                </ListGroupItem>
                              );
                            }
                          )}
                      </ListGroup>
                    </Col>
                    <Col sm={12} md={12} lg={6}>
                      <ListGroup className="descargaIndicadores__badActorSmall">
                        <ListGroupItemHeading>
                          Entregas Atrasadas TOP 5{'  '}
                          <a
                            className="font-weight-semi-bold fs--1 text-nowrap"
                            href={`${baseUrl}/kpis/excel/descargaAtrasada/${idGrupo.id_cliente}/${dataInicial}/${dataFinal}`}
                          >
                            <FontAwesomeIcon
                              icon="cloud-download-alt"
                              transform="down-1.5"
                              className="ml-1"
                            />
                          </a>
                        </ListGroupItemHeading>
                        {data.topFiveEntregasAtrasada.length > 0 &&
                          data.topFiveEntregasAtrasada.map((item, index) => {
                            return (
                              <ListGroupItem
                                key={index}
                                style={{ fontSize: 11 }}
                              >
                                <div>
                                  <Row>
                                    <h6>
                                      {item.destino + '  '}
                                      <Badge color={'soft-warning'} pill>
                                        {' '}
                                        {item.qtde_atrasado}{' '}
                                      </Badge>
                                    </h6>
                                  </Row>
                                </div>
                              </ListGroupItem>
                            );
                          })}
                      </ListGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <div className="mt-3">
                <SmallCard
                  description="Peso total carregado:"
                  value={data.pesoCarregado}
                />
              </div>
            </Col>
            <Col sm={12} md={12} lg={3} className="descargaIndicadores__onTime">
              <IndicadorCircular
                total={parseInt(data.totalCargas)}
                saved={
                  parseInt(data.totalCargas) -
                  (parseInt(data.totalEntregaAtrasada) -
                    parseInt(data.carrAtrasadosNaoControlaveis))
                }
                title="Ontime (Descarga)  "
                iconButton={
                  <a
                    className="font-weight-semi-bold fs--1 text-nowrap position-absolute z-index-2 ms-2"
                    href={`${baseUrl}/kpis/excel/descargaOntime/${idGrupo.id_cliente}/${dataInicial}/${dataFinal}`}
                  >
                    <FontAwesomeIcon
                      icon="cloud-download-alt"
                      transform="down-1.5"
                      className="ml-1"
                    />
                  </a>
                }
              />
            </Col>
          </Row>
        </>
      )}
    </Fragment>
  );
};

export default DescargaKPI;
