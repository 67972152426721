import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Button } from 'react-bootstrap';
import Logo from 'components/common/Logo';
import { useNavigate } from 'react-router-dom';

import Section from 'components/common/Section';
import CarrouselGallery from 'components/carousel-gallery';

const EmConfiguracaoLayout = () => {
  const navigate = useNavigate();
  return (
    <Section className="py-0">
      <Row className="flex-center">
        <Col sm={12}>
          <Logo />
          <p className="text-center">
            Ambiente em configuração, logo nossa equipe de TI entrará em contato
            com sua empresa.
          </p>
          <Button
            onClick={() => {
              navigate(-1);
            }}
            className="btn btn-outline-facebook text-white"
          >
            Voltar
          </Button>
        </Col>
      </Row>
    </Section>
  );
};

EmConfiguracaoLayout.propTypes = {
  match: PropTypes.object
};

export default EmConfiguracaoLayout;
