import React, { Fragment, useContext } from 'react';
import './map.css';
import { Accordion, Button, Card, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const NavBarMap = props => {
  // eslint-disable-next-line react/prop-types
  const { lng, lat, zoom, pitch, bearing } = props;

  const backTracking = () => {
    return (window.location.href = '/dashboard/tracking');
  };

  return (
    <Fragment>
      <Card.Header className="p-0 m-3" id={`AccordionHeading0`}>
        <FontAwesomeIcon
          icon="caret-right"
          className="accordion-icon me-3 "
          transform="shrink-2"
        />
        <span className="fw-medium font-sans-serif text-900">Navegação</span>
      </Card.Header>
      {/* <Row>
            <Col>
              <Button onClick={backTracking}>Voltar</Button>
            </Col>
          </Row> */}
      <Row className="m-3">
        <Col sm={12} md={6} lg={6}>
          Longitude: {lng}
        </Col>
        <Col sm={12} md={6} lg={6}>
          Latitude: {lat}
        </Col>
        <Col sm={12} md={6} lg={6}>
          Zoom: {zoom}
        </Col>
        <Col sm={12} md={6} lg={6}>
          Pitch: {pitch}
        </Col>
        <Col sm={12} md={6} lg={6}>
          Bearing: {bearing}
        </Col>
      </Row>
    </Fragment>
  );
};

export default NavBarMap;
