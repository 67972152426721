import React, { useContext } from 'react';
import './map.css';
import { Accordion, Button, Card, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const NavBarMap = props => {
  // eslint-disable-next-line react/prop-types
  const { lng, lat, zoom, pitch, bearing, toggleVisibility } = props;

  const backHome = () => {
    return (window.location.href = '/dashboard/viagens');
  };

  return (
    <Accordion>
      <Accordion.Item>
        <Card.Header className="p-0" id={`AccordionHeading0`}>
          <Accordion.Button className="btn btn-link text-decoration-none d-block w-100 py-2 px-3 border-0 rounded-0 text-start shadow-none">
            <FontAwesomeIcon
              icon="caret-right"
              className="accordion-icon me-3 "
              transform="shrink-2"
            />
            <span className="fw-medium font-sans-serif text-900">
              Navegação
            </span>
          </Accordion.Button>
        </Card.Header>
        <Accordion.Body>
          <Row>
            <Col>
              <Button onClick={backHome}>Início</Button>
            </Col>
            <Col>
              <Button onClick={toggleVisibility}>Simular alerta</Button>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={12} lg={12}>
              Longitude: {lng}
            </Col>
            <Col sm={12} md={12} lg={12}>
              Latitude: {lat}
            </Col>
            <Col sm={12} md={12} lg={12}>
              Zoom: {zoom}
            </Col>
            <Col sm={12} md={12} lg={12}>
              Pitch: {pitch}
            </Col>
            <Col sm={12} md={12} lg={12}>
              Bearing: {bearing}
            </Col>
          </Row>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default NavBarMap;
