import React, {
  useRef,
  useEffect,
  useState,
  Fragment,
  useCallback
} from 'react';
import mapboxgl from '!mapbox-gl';
import MapboxTraffic from '!@mapbox/mapbox-gl-traffic';
import 'mapbox-gl/dist/mapbox-gl.css';
import './map.css';
import NavBarMap from './navbar';
import axios from 'axios';
import { baseUrl } from '../../../config';
import markerImg from '../../../image/custom_marker.png';
import ViagensBarMap from './viagensbar';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';
import { Col, Row } from 'react-bootstrap';
import FiltersMap from './filters';
import som from '../../../sound/beep-warning.mp3';
import classNames from 'classnames';
import { Controls, Player } from '@lottiefiles/react-lottie-player';
import { updateFilter } from '../../../slices/filters';

mapboxgl.accessToken =
  'pk.eyJ1IjoiYXhvbmFwaSIsImEiOiJjbGRudWE1dGowNHp3M3hudmJ4MTA2YjEwIn0.GkwFZHDIw2A3Le9BuyYa-A';

const TowerRealTimePage = () => {
  const mapContainer = useRef(null);
  const map = useRef(null);

  const [lng, setLng] = useState(-58);
  const [lat, setLat] = useState(-16);
  const [pitch, setPitch] = useState(1);
  const [bearing, setBearing] = useState(1);
  const [zoom, setZoom] = useState(4);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [atualizadoEm, setAtualizadoEm] = useState(new Date().toISOString());

  const [coordinates, setCoordinates] = useState([]);
  const user = JSON.parse(sessionStorage.getItem('user'));

  const filters = useSelector(state => state.filters);
  const configMapa = useSelector(state => state.configMapa); //state.configMapa pega o store configurado

  const divRef = useRef(null);

  const dispatch = useDispatch();

  const imageMarker = new Image();
  imageMarker.src = markerImg;

  const audioRef = useRef(null);

  const playAudio = () => {
    if (audioRef.current) {
      audioRef.current.play();
    }
  };
  const pauseAudio = () => {
    if (audioRef.current) {
      audioRef.current.pause();
    }
  };

  const base64 = btoa(
    JSON.stringify({
      id_grupo_cliente: user.id_grupo_cliente,
      grupo_destinatario: 0,
      lng: lng,
      lat: lat,
      zoom: zoom,
      pitch: 0,
      bearing: 0
    })
  );

  const axon_markers = {
    type: 'geojson',
    data: `${baseUrl}/map/v2/getAllTrips?base64=${base64}`
  };
  const trucks_circle = {
    id: 'trucks-circle',
    type: 'symbol',
    source: 'axon_markers',
    layout: {
      'icon-image': 'custom-marker',
      'icon-allow-overlap': true,
      'icon-anchor': 'bottom',
      'icon-size': 1
    },
    paint: {
      'icon-color': [
        'match',
        ['get', 'status'],
        'Destinado',
        '#3c7a16',
        'Descarga Parcial',
        '#CCC',
        'Viagem',
        '#0f52a9',
        'Vazio',
        '#ffea00',
        'Descarga',
        '#CCC',
        'Carga',
        '#868686',
        '#CCC' // any other store type
      ]
    }
  };
  const trucks_circle_back = {
    id: 'trucks-circle-back',
    type: 'circle',
    source: 'axon_markers',
    layout: {
      visibility: 'visible'
    },
    paint: {
      'circle-radius': {
        stops: [
          [4, 20],
          [16, 120]
        ]
      },
      'circle-opacity': 0.4,
      'circle-pitch-scale': 'viewport',
      'circle-color': [
        'match',
        ['get', 'nac_no_prazo'],
        '0',
        '#ff003d', //atrasado
        '1',
        '#20ec09', //no prazo
        '2',
        '#ffff00', //risco atraso
        '#ffff00' // any other store type
      ]
    }
  };
  const trucks_cluster_count = {
    id: 'trucks-cluster-count',
    type: 'symbol',
    source: 'axon_markers',
    layout: {
      'text-field': ['get', 'message'],
      'text-offset': [0, -3],
      'icon-allow-overlap': true,
      'text-size': 36
    },
    paint: {
      'text-color': '#f9dc02',
      'text-halo-color': '#000',
      'text-halo-width': 3
    }
  };

  const toggleVisibility = () => {
    dispatch(
      updateFilter({
        label: 'alerta',
        value: !filters.filters.alerta
      })
    );
    playAudio();
  };

  useEffect(() => {
    document.body.style.overflow = 'hidden';
  }, []);

  useEffect(
    useCallback(() => {
      const interval = setInterval(() => {
        if (configMapa.conf.visualizacaoDinamica === true) {
          changeZoom();
        }
      }, 10000);
      return () => clearInterval(interval);
    }),
    []
  );

  useEffect(
    useCallback(() => {
      if (map.current) return;
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: configMapa.conf.tipo,
        center: [lng, lat],
        zoom: zoom,
        attributionControl: false
      });

      map.current.on('load', () => {
        map.current.addControl(new MapboxTraffic());
        map.current.addControl(new mapboxgl.NavigationControl(), 'top-right');

        console.log('Load cities');
        // https://github.com/tbrugz/geodata-br/tree/master

        map.current.addSource('city-geojson', {
          type: 'geojson',
          data: '../../../maps/limites.json'
        });

        map.current.addLayer({
          id: 'city-layer',
          type: 'fill',
          source: 'city-geojson',
          layout: {},
          paint: {
            'fill-color': '#03ff00',
            'fill-opacity': 0.5
          }
        });

        map.current.addLayer({
          id: 'city-outline',
          type: 'line',
          source: 'city-geojson',
          layout: {},
          paint: {
            'line-color': '#000',
            'line-width': 2
          }
        });

        // Áreas de risco
        map.current.addSource('city-geojson-risk', {
          type: 'geojson',
          data: '../../../maps/risks-zone.json'
        });

        map.current.addLayer({
          id: 'city-layer-risk',
          type: 'fill',
          source: 'city-geojson-risk',
          layout: {},
          paint: {
            'fill-color': '#ff4040',
            'fill-opacity': 0.5
          }
        });

        map.current.addLayer({
          id: 'city-outline-risk',
          type: 'line',
          source: 'city-geojson-risk',
          layout: {},
          paint: {
            'line-color': '#000',
            'line-width': 2
          }
        });
      });

      map.current.on('move', () => {
        setLng(map.current.getCenter().lng.toFixed(6));
        setLat(map.current.getCenter().lat.toFixed(6));
        setPitch(map.current.getPitch().toFixed(2));
        setBearing(map.current.getBearing().toFixed(2));
        setZoom(map.current.getZoom().toFixed(2));
      });

      map.current.on('click', event => {
        const features = map.current.queryRenderedFeatures(event.point, {
          layers: ['trucks-circle']
        });
        if (!features.length) {
          return;
        }
        const feature = features[0];

        const popup = new mapboxgl.Popup({ offset: [0, -15] })
          .setLngLat(feature.geometry.coordinates)
          .setHTML(
            `<h5>${feature.properties.pedido_shipment}</h5><p>${feature.properties.destino}</p>`
          )
          .addTo(map.current);
      });

      map.current.on('styledata', () => {
        if (!map.current.getSource('axon_markers')) {
          map.current.addSource('axon_markers', axon_markers);
          map.current.addImage('custom-marker', imageMarker, { sdf: true });
        }

        if (!map.current.getLayer('trucks-circle-back')) {
          map.current.addLayer(trucks_circle_back);
          map.current.addLayer(trucks_circle);
          map.current.addLayer(trucks_cluster_count);
        }
      });
      return true;
    }),
    []
  );

  useEffect(() => {
    map.current.setStyle(configMapa.conf.tipo);
  }, [configMapa]);

  useEffect(() => {
    if (!map || !coordinates) return;
    getMarkers();
    return true;
  }, [map, coordinates]);

  const getMarkers = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${sessionStorage.token}`
      },
      params: {
        dataInicial: filters.filters.dataInicial.toString(),
        dataFinal: filters.filters.dataFinal.toString(),
        placa: filters.filters.placa,
        origem: filters.filters.origem,
        status: filters.filters.status,
        notaFiscal: filters.filters.notaFiscal,
        base64: base64
      }
    };

    axios
      .get(`${baseUrl}/map/v2/getAllTrips`, config)
      .then(res => {
        if (res.status === 200) {
          map.current.getSource('axon_markers').setData(res.data);
          setData(res.data);
          setAtualizadoEm(res.data.atualizado_em);
          setIsLoading(false);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  const changeZoom = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${sessionStorage.token}`
      }
    };

    axios.get(`${baseUrl}/map/v2/getZoom/${base64}`, config).then(res => {
      map.current.flyTo({
        center: [res.data.lon, res.data.lat],
        zoom: res.data.zoom,
        pitch: parseInt(res.data.pitch),
        bearing: parseInt(res.data.bearing),
        speed: 0.7,
        curve: 1,
        essential: true
      });
    });
  };

  return (
    <Fragment>
      <div
        ref={divRef}
        className={classNames('floating-div')}
        id="csvDataDiv"
        style={{
          display: filters.filters.alerta ? 'block' : 'none'
        }}
      >
        <Player
          autoplay
          loop
          speed={2}
          src="https://lottie.host/19feab0b-e9bb-4f1f-930c-c55c72175f8d/o6crTYVdxA.json"
          style={{ height: '300px', width: '300px' }}
        >
          <Controls
            visible={false}
            buttons={['play', 'repeat', 'frame', 'debug']}
          />
        </Player>
      </div>
      <Row>
        <Col sm={12} md={12} lg={10} xl={9}>
          <div style={{ height: '100vh' }}>
            <div ref={mapContainer} style={{ height: '100vh' }} />
          </div>
        </Col>
        <Col sm={12} md={12} lg={2} xl={3} className="p-0 pe-4">
          <Row>
            <Col sm={12} md={12} lg={12} xs={12}>
              <FiltersMap getMarkers={getMarkers} />
            </Col>
            <Col sm={12} md={12} lg={12} xs={12} className="mt-3">
              {isLoading ? (
                <Spinner size="sm" color="primary" className="mt-3" />
              ) : (
                <ViagensBarMap data={data} atualizadoEm={atualizadoEm} />
              )}
            </Col>
            <Col sm={12} md={12} lg={12} xs={12} className="mt-3">
              <NavBarMap
                toggleVisibility={toggleVisibility}
                lat={lat}
                lng={lng}
                zoom={zoom}
                isLoading={isLoading}
                pitch={pitch}
                bearing={bearing}
              />
              <audio ref={audioRef} src={som} preload="auto" loop={true} />
            </Col>
          </Row>
        </Col>
      </Row>
    </Fragment>
  );
};

export default TowerRealTimePage;
