import React from 'react';
import { Card } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import CardDropdown from 'components/common/CardDropdown';
import TrafficSourceChart from './TrafficSourceChart';

function TrafficSource() {
  return (
    <Card className="h-100">
      <FalconCardHeader
        title="Traffic source"
        titleTag="h6"
        className="py-2"
        light
        endEl={<CardDropdown />}
      />
      <Card.Body className="pb-0">
        <TrafficSourceChart />
      </Card.Body>
    </Card>
  );
}

export default TrafficSource;
