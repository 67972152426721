import React, { useState, useEffect, Fragment } from 'react';
import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  Col,
  Form,
  Modal,
  Row
} from 'react-bootstrap';
import * as yup from 'yup';
import PageHeader from '../../../components/pages/pageHeader';

import '../../eva/styleEva.css';
import { useFormik } from 'formik';
import Divider from '../../../components/common/Divider';
import axios from 'axios';
import { baseUrl } from '../../../config';
import AlertErrorGetData from '../../../components/alert/errorGetData';
import Swal from 'sweetalert2';
import moment from 'moment-timezone';

import DatePicker, { registerLocale } from 'react-datepicker';

import ptBR from 'date-fns/locale/pt-BR';
import { format } from 'date-fns';

registerLocale('pt-BR', ptBR);

const SolicitacaoDemandasViagens = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const timeZone = 'America/Sao_Paulo';
  const formatDate = date => {
    return moment.tz(date, timeZone).format('DD/MM/YYYY HH:mm');
  };

  const sendLoadRequest = (values, resetForm) => {
    const config = {
      headers: {
        Authorization: `Bearer ${sessionStorage.token}`
      }
    };

    axios
      .post(`${baseUrl}/cct/sendLoadRequest`, values, config)
      .then(response => {
        if (response.status === 200) {
          resetForm();
        }

        return Swal.fire({
          icon: 'success',
          title: 'Sucesso',
          text: 'Solicitação enviada com sucesso.',
          cancelButtonText: 'Ok',
          reverseButtons: true,
          confirmButtonColor: '#2b7be5'
        });
      })
      .catch(error => {
        AlertErrorGetData('Erro ao enviar.');
      });
  };

  const formik = useFormik({
    initialValues: {
      sender: '',
      recipient: '',
      origin: '',
      destination: '',
      loading_date: '',
      delivery_date: '',
      weight: '',
      cargo_value: ''
    },
    validationSchema: yup.object({
      sender: yup.string().required('O campo é obrigatório.'),
      recipient: yup.string().required('O campo é obrigatório.'),
      origin: yup.string().required('O campo é obrigatório.'),
      destination: yup.string().required('O campo é obrigatório.'),
      loading_date: yup.string().required('O campo é obrigatório.'),
      weight: yup.string().required('O campo é obrigatório.')
    }),
    onSubmit: (values, { resetForm }) => {
      sendLoadRequest(values, resetForm);
    }
  });

  const send = () => {
    formik.submitForm();
  };

  return (
    <Fragment>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Nova solicitação</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Col>
                Utilize o formulário abaixo para enviar sua solicitação de
                carga, em até 24 horas nosso SAC irá entrar em contato.
              </Col>
            </Row>
            <Divider>-</Divider>
            <Row>
              <Col sm={12} md={12} lg={6} className="mb-2">
                <Form.Label>Remetente</Form.Label>
                <Form.Control
                  type="text"
                  name="sender"
                  id="sender"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.sender}
                  className={`form-control ${
                    formik.touched.sender && formik.errors.sender
                      ? 'is-invalid'
                      : ''
                  }`}
                />
              </Col>
              <Col sm={12} md={12} lg={6} className="mb-2">
                <Form.Label>Destinatário</Form.Label>
                <Form.Control
                  type="text"
                  name="recipient"
                  id="recipient"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.recipient}
                  className={`form-control ${
                    formik.touched.recipient && formik.errors.recipient
                      ? 'is-invalid'
                      : ''
                  }`}
                />
              </Col>
              <Col sm={12} md={12} lg={6} className="mb-2">
                <Form.Label>Origem</Form.Label>
                <Form.Control
                  type="text"
                  name="origin"
                  id="origin"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.origin}
                  className={`form-control ${
                    formik.touched.origin && formik.errors.origin
                      ? 'is-invalid'
                      : ''
                  }`}
                />
              </Col>
              <Col sm={12} md={12} lg={6} className="mb-2">
                <Form.Label>Destino</Form.Label>
                <Form.Control
                  type="text"
                  name="destination"
                  id="destination"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.destination}
                  className={`form-control ${
                    formik.touched.destination && formik.errors.destination
                      ? 'is-invalid'
                      : ''
                  }`}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={12} lg={4} className="mb-2">
                <Form.Label>Peso</Form.Label>
                <Form.Control
                  type="text"
                  name="weight"
                  id="weight"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.weight}
                  className={`form-control ${
                    formik.touched.weight && formik.errors.weight
                      ? 'is-invalid'
                      : ''
                  }`}
                />
              </Col>
              <Col sm={12} md={12} lg={5} className="mb-2">
                <Form.Label>Valor da carga</Form.Label>
                <Form.Control
                  type="text"
                  name="cargo_value"
                  id="cargo_value"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.cargo_value}
                  className={`form-control ${
                    formik.touched.cargo_value && formik.errors.cargo_value
                      ? 'is-invalid'
                      : ''
                  }`}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={12} lg={5} className="mb-2">
                <Form.Label>Data/Carregamento</Form.Label>
                <DatePicker
                  showTimeSelect
                  selected={
                    formik.values.loading_date
                      ? new Date(formik.values.loading_date)
                      : null
                  }
                  name="loading_date"
                  id="loading_date"
                  locale="pt-BR"
                  dateFormat="dd/MM/yyyy HH:mm"
                  onChange={date => {
                    const zonedDate = moment.tz(date, timeZone).utc();
                    formik.setFieldValue(
                      'loading_date',
                      zonedDate.toISOString()
                    );
                  }}
                  onBlur={formik.handleBlur}
                  value={
                    formik.values.loading_date
                      ? formatDate(new Date(formik.values.loading_date))
                      : ''
                  }
                  className={`form-control ${
                    formik.touched.loading_date && formik.errors.loading_date
                      ? 'is-invalid'
                      : ''
                  }`}
                />
              </Col>
              <Col sm={12} md={12} lg={5} className="mb-2">
                <Form.Label>Data/Entrega</Form.Label>
                <DatePicker
                  showTimeSelect
                  selected={
                    formik.values.delivery_date
                      ? new Date(formik.values.delivery_date)
                      : null
                  }
                  name="delivery_date"
                  id="delivery_date"
                  locale="pt-BR"
                  dateFormat="dd/MM/yyyy HH:mm"
                  onChange={date => {
                    const zonedDate = moment.tz(date, timeZone).utc();
                    formik.setFieldValue(
                      'delivery_date',
                      zonedDate.toISOString()
                    );
                  }}
                  onBlur={formik.handleBlur}
                  value={
                    formik.values.delivery_date
                      ? formatDate(new Date(formik.values.delivery_date))
                      : ''
                  }
                  className={`form-control ${
                    formik.touched.delivery_date && formik.errors.delivery_date
                      ? 'is-invalid'
                      : ''
                  }`}
                />
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Divider>Novidade</Divider>
          <p>
            <Badge>Integração</Badge> Faça solicitações diretamente de seu ERP
            para nosso TMS.
          </p>
          <p>
            <Badge>Automatização</Badge> Conheça nosso Supply chain management,
            para mais informações entre em contato pelo e-mail
            comercial@aiqia.com.br
          </p>
          <Divider>-</Divider>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={send}>
            Enviar
          </Button>
        </Modal.Footer>
      </Modal>
      <PageHeader
        title="Solicitações de viagens"
        description={`Favor preencha o formulário abaixo para solicitar.`}
        // eslint-disable-next-line
                children={
          <>
            <Form className="g-5">
              <Row>
                <Col>
                  <Button onClick={handleShow}>Novo</Button>
                </Col>
              </Row>
            </Form>
          </>
        }
      />
    </Fragment>
  );
};

export default SolicitacaoDemandasViagens;
