import React from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { getColor } from 'helpers/utils';
import * as echarts from 'echarts/core';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { BarChart } from 'echarts/charts';
import {
  GridComponent,
  TooltipComponent,
  TitleComponent,
  DatasetComponent,
  LegendComponent
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  BarChart,
  CanvasRenderer,
  LegendComponent,
  DatasetComponent
]);

const handleLegendToggle = () => {
  //
};

const getOption = data => ({
  color: [getColor('primary'), getColor('300')],
  dataset: { source: data },
  tooltip: {
    trigger: 'item',
    padding: [7, 10],
    backgroundColor: getColor('100'),
    borderColor: getColor('300'),
    textStyle: { color: getColor('dark') },
    borderWidth: 1,
    transitionDuration: 0,
    formatter: function (params) {
      return `<div className="fw-semi-bold">${params.data.dia}</div><div className="fs--1 text-600"><strong>Peso (t):</strong> ${params.data.peso}</div><div className="fs--1 text-600"><strong>Km:</strong> ${params.data.km}</div>`;
    }
  },
  legend: {
    data: ['Peso', 'Km'],
    left: 'left',
    itemWidth: 10,
    itemHeight: 10,
    borderRadius: 0,
    icon: 'circle',
    inactiveColor: getColor('400'),
    textStyle: { color: getColor('700') }
  },
  xAxis: {
    type: 'category',
    axisLabel: { color: getColor('400') },
    axisLine: {
      lineStyle: {
        color: getColor('300'),
        type: 'dashed'
      }
    },
    axisTick: false,
    boundaryGap: true
  },
  yAxis: {
    axisPointer: { type: 'none' },
    axisTick: 'none',
    splitLine: {
      lineStyle: {
        color: getColor('300'),
        type: 'dashed'
      }
    },
    axisLine: { show: false },
    axisLabel: { color: getColor('400') }
  },
  series: [
    {
      type: 'bar',
      barWidth: '25px',
      barGap: '30%',
      label: {
        show: true,
        position: 'top',
        textStyle: {
          textBorderColor: 'transparent',
          color: getColor('700'),
          fontSize: 10
        }
      },
      z: 10,
      itemStyle: {
        borderRadius: [5, 5, 0, 0],
        color: getColor('primary')
      }
    },
    {
      type: 'bar',
      barWidth: '25px',
      barGap: '30%',
      label: {
        show: true,
        position: 'top',
        textStyle: {
          textBorderColor: 'transparent',
          color: getColor('700'),
          fontSize: 10
        }
      },
      itemStyle: {
        borderRadius: [5, 5, 0, 0],
        color: getColor('warning')
      }
    }
  ],
  grid: { right: '0', left: '50px', bottom: '10%', top: '20%' }
});

const PesoKm = ({ data, load }) => {
  return (
    <Card className="h-100 mb-3">
      <Card.Header>
        <Row className="flex-between-center g-0">
          <Col xs="auto">
            <h6 className="mb-0">
              {/* eslint-disable-next-line react/prop-types */}
              {'Peso e KM da semana'}
            </h6>
          </Col>
          <Col xs="auto" className="d-flex">
            <Form.Check
              type="checkbox"
              id="ecommerceLastMonth"
              className="mb-0 d-flex"
            >
              <Form.Check.Input
                type="checkbox"
                className="form-check-input-primary"
                onClick={() => handleLegendToggle('lastMonth')}
                defaultChecked
              />
              <Form.Check.Label className="ps-2 fs--2 text-600 mb-0">
                Peso
              </Form.Check.Label>
            </Form.Check>
            <Form.Check
              type="checkbox"
              id="ecommercePrevYear"
              className="mb-0 d-flex ps-0 ps-md-3"
            >
              <Form.Check.Input
                type="checkbox"
                className="ms-2 form-check-input-warning opacity-75"
                onClick={() => handleLegendToggle('previousYear')}
                defaultChecked
              />
              <Form.Check.Label className="ps-2 fs--2 text-600 mb-0">
                Km
              </Form.Check.Label>
            </Form.Check>
          </Col>
          <Col xs="auto">
            <h6>{''}</h6>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body className="h-100">
        <ReactEChartsCore
          showLoading={load}
          echarts={echarts}
          option={getOption(data)}
          style={{ height: '100%', minHeight: '17.75rem' }}
        />
      </Card.Body>
    </Card>
  );
};

PesoKm.propTypes = {
  data: PropTypes.array.isRequired,
  load: PropTypes.bool
};

export default PesoKm;
