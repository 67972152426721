import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useAuth } from '../../hooks/AuthContext';

const LoginFormAdmin = ({ hasLabel, layout }) => {
  // State
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    cliente: '',
    remember: false
  });

  const { loginAdmin, error } = useAuth();

  // Handler
  const handleSubmit = async e => {
    e.preventDefault();

    const email = formData.email;
    const password = formData.password;
    const cliente = formData.cliente;

    await loginAdmin(email, password, cliente);
  };

  const handleFieldChange = e => {
    console.log({
      ...formData,
      [e.target.name]: e.target.value
    });
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>E-mail</Form.Label>}
        <Form.Control
          autoComplete="off"
          placeholder={!hasLabel ? 'E-mail' : ''}
          value={formData.email}
          name="email"
          onChange={handleFieldChange}
          type="email"
        />
      </Form.Group>
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Cliente</Form.Label>}
        <Form.Control
          autoComplete="off"
          placeholder={!hasLabel ? 'Cliente' : ''}
          value={formData.cliente}
          name="cliente"
          onChange={handleFieldChange}
          type="text"
        />
      </Form.Group>
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Senha</Form.Label>}
        <Form.Control
          autoComplete="off"
          placeholder={!hasLabel ? 'Senha' : ''}
          value={formData.password}
          name="password"
          onChange={handleFieldChange}
          type="password"
        />
      </Form.Group>

      <Row className="justify-content-between align-items-center">
        <Col xs="auto">
          <Form.Check type="checkbox" id="rememberMe" className="mb-0">
            <Form.Check.Input
              type="checkbox"
              name="remember"
              checked={formData.remember}
              onChange={e =>
                setFormData({
                  ...formData,
                  remember: e.target.checked
                })
              }
            />
            <Form.Check.Label className="mb-0 text-700">
              Ficar conectado
            </Form.Check.Label>
          </Form.Check>
        </Col>

        <Col xs="auto">
          <Link
            className="fs--1 mb-0"
            to={`/authentication/${layout}/forgot-password`}
          >
            Esqueceu sua senha?
          </Link>
        </Col>
      </Row>

      <Form.Group>
        <Button
          type="submit"
          color="primary"
          className="mt-3 w-100"
          disabled={!formData.email || !formData.password}
        >
          Acessar
        </Button>
      </Form.Group>
    </Form>
  );
};

LoginFormAdmin.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

LoginFormAdmin.defaultProps = {
  layout: 'simple',
  hasLabel: false
};

export default LoginFormAdmin;
