import axios from 'axios';
import { baseUrl } from 'config';
import React, { Fragment, useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { Button } from 'reactstrap';

const TableTopTen = ({ topDez }, load) => {
  // const [load, setLoad] = useState(false); //Mudar para true
  const [resData, setResData] = useState([]);
  const [total, setTotal] = useState([]);
  const [agrupador, setAgrupado] = useState([]);
  //  const [topDez, setTopDez] = useState([]);
  // const getData = () => {
  //   const config = {
  //     headers: {
  //       Authorization: `Bearer ${sessionStorage.token}`
  //     }
  //   };

  //   axios
  //     .get(
  //       `${baseUrl}/kpis/getLoadsTotal?codGrupoNegociador=${usuario.id_grupo_cliente}&dataInicio=${di}&dataFim=${df}`,
  //       config
  //     )
  //     .then(response => {
  //       setResData(response.data);
  //       setTotal(response.data.total);
  //       setAgrupado(response.data.agrupado);
  //       setTopDez(response.data.topDez);
  //     })
  //     .catch(error => {
  //       console.log(`Erro ${error}`);
  //     })
  //     .finally(() => {
  //       setLoad(true);
  //     });
  // };
  const usuario = JSON.parse(sessionStorage.getItem('user'));

  // useEffect(() => {
  //   getData();
  // }, []);
  console.log(topDez.length);
  return (
    <Fragment>
      <Table striped hover>
        <thead className=" border border-bottom-10">
          <tr>
            <th className="d-flex">
              <b>Top 10</b>
            </th>
            <th className="text-center">
              <b>Origem</b>
            </th>
            <th className=" text-center">
              <b>Destino</b>
            </th>
            <th className="text-center">
              <b>Qtde</b>
            </th>
          </tr>
        </thead>

        <tbody>
          {topDez.length == 0 ? (
            <> Sem Registro </>
          ) : (
            topDez.map((userComp, index) => (
              <tr key={index}>
                <td>{userComp.nome}</td>
                <td className="text-center">{userComp.origem}</td>
                <td className="text-center">{userComp.destino}</td>
                <td className="text-center">{userComp.quantidade}</td>
              </tr>
            ))
          )}
        </tbody>
      </Table>
    </Fragment>
  );
};
export default TableTopTen;
