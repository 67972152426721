import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Figure, Row } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import Background from 'components/common/Background';
import evaBio2 from 'assets/img/eva/eva-bio2.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createMarkup from '../../../../helpers/createMarkup';

const GreetingCard = ({ usuario }) => {
  const [test, setTest] = useState();
  return (
    <Card className="bg-transparent-50 overflow-hidden">
      <Card.Header className="position-relative">
        <Background
          image={evaBio2}
          className="d-none d-md-block bg-card z-index-1"
          style={{
            backgroundSize: '230px',
            backgroundPosition: 'right bottom',
            zIndex: '-1'
          }}
        />
        <div className="position-relative z-index-2  d-flex flex-direction-row">
          {/* {test ? (
            <></>
          ) : (
            <Figure
              className="mt-3 mb-3 mr-3"
              style={{ width: '6em', height: '6em', marginRight: '1em' }}
            >
              <img
                className="me-2"
                style={{ maxWidth: '100%', height: 'auto' }}
                src="https://polofilms.com.br/static/images/lgPoloColor-2.png"
              />
            </Figure>
          )} */}
          <div>
            <div>
              <h3 className="text-primary mb-1">
                Bem vindo(a), {usuario.name}!
              </h3>
            </div>
            <Flex className="py-3">
              <div className="pe-3">
                <h4>Parabéns</h4>
                <div className="text-warning mb-0" style={{ width: '80%' }}>
                  A Axon leva a sério a diminuição na emissão de CO². Programa
                  carga consciente.
                </div>
              </div>
            </Flex>
          </div>
        </div>
      </Card.Header>
      <Card.Body className="p-0">
        <ul className="mb-0 list-unstyled">
          <li className="alert mb-0 rounded-0 py-3 px-card alert-warning">
            <Row className="flex-between-center">
              <Col>
                <Flex>
                  <FontAwesomeIcon
                    icon="circle"
                    className="mt-1 fs--2 alert-warning"
                  />
                  <p
                    className="fs--1 ps-2 mb-0"
                    dangerouslySetInnerHTML={createMarkup(
                      'Você não possui novas mensagens!'
                    )}
                  ></p>
                </Flex>
              </Col>
            </Row>
          </li>
        </ul>
      </Card.Body>
    </Card>
  );
};

GreetingCard.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.object),
  usuario: PropTypes.string
};

export default GreetingCard;
