export default {
  loginEndpoint: 'client-login',
  loginAdminEndpoint: 'change-login-admin',
  registerEndpoint: 'register',
  refreshEndpoint: 'refresh-token',
  logoutEndpoint: 'logout',

  tokenType: 'Bearer',

  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
  storageUserId: 'userId',
  storageUserName: 'userName',
  storageUserEmail: 'userEmail',
  storageUserGroup: 'userGroup',
  storageUserData: 'userData'
};
