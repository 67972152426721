import React, { useContext, useEffect, useCallback } from 'react';
import { Outlet, useLocation, Navigate } from 'react-router-dom';
import NavbarTop from 'components/navbar/top/NavbarTop';
import NavbarVertical from 'components/navbar/vertical/NavbarVertical';
import AppContext from 'context/Context';
import Footer from 'components/footer/Footer';
import classNames from 'classnames';
import { useAuth } from '../hooks/AuthContext';
import { loadFull } from 'tsparticles';

const MainLayout = () => {
  const { hash, pathname } = useLocation();
  const isKanban = pathname.includes('kanban');
  const {
    config: { isDark, isRTL },
    setConfig
  } = useContext(AppContext);

  const { user } = useAuth();

  if (!user) {
    return <Navigate to="/login" />;
  }

  const {
    config: { isFluid, navbarPosition }
  } = useContext(AppContext);

  useEffect(() => {
    setTimeout(() => {
      if (hash) {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
      }
    }, 0);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const particlesInit = useCallback(async engine => {
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async container => {}, []);

  return (
    <>
      <div
        className={isFluid ? 'container-fluid' : 'container'}
        style={{ zIndex: 99 }}
      >
        {(navbarPosition === 'vertical' || navbarPosition === 'combo') && (
          <NavbarVertical />
        )}
        <div className={classNames('content', { 'pb-0': isKanban })}>
          <NavbarTop />
          <Outlet />
          {!isKanban && <Footer />}
        </div>
      </div>
    </>
  );
};

export default MainLayout;
