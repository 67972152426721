import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import GreetingCard from './greetings/GreetingCard';
import { totalOrder } from 'data/dashboard/ecom';
import axios from 'axios';
import CountUp from 'react-countup';
import { baseUrl } from '../../../config';
import PesoMedio from 'pages/dashboards/default/PesoMedio';
import ViagensEmAberto from './ViagensEmAbertoGrafico/ViagensEmAberto';
import SituacaoViagens from 'pages/dashboards/default/SituacaoViagens';
import KmMedioPeriodo from 'pages/dashboards/viagens/KmMedioPeriodo';
import ComparativoSemanal from './ComparativoSemanal/ComparativoSemanal';
import PesoKm from './GraficoPeso/PesoKm';
import moment from 'moment/moment';

const Viagens = () => {
  const [load, setLoad] = useState(true);
  const dataFinal = moment().format(`YYYY-MM-DD`);
  const dataInicial = moment().subtract(12, 'months').format(`YYYY-MM-DD`);
  const [loadViagemAberto, setLoadViagemAberto] = useState(true);
  const [loadKmmMedio, setLoadKmmMedio] = useState(true);
  const [carbono, setCarbono] = useState(0);
  const [periodoDias, setPeriodoDias] = useState(30);
  const [kmByMes, setKmViagensByMes] = useState([]);
  const [totalViagens, setTotalViagens] = useState(0);
  const [totalViagensEmAberto, setTotalViagensEmAberto] = useState(0);
  const [totalPeso, setTotalPeso] = useState(0);
  const [totalKM, setTotalKM] = useState(0);
  const [percTotalAtendidas, setPercTotalAtendidas] = useState(0);
  const [situacaoViagem, setSituacaoViagem] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [kmHistorico, setKmHistorico] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [pesoHistorico, setPesoHistorico] = useState([]);
  const [historico, setHistorico] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [compSem1, setCompSem1] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [compSem2, setCompSem2] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [compSem3, setCompSem3] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [compSem4, setCompSem4] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [label, setLabel] = useState([]);
  const usuario = JSON.parse(sessionStorage.getItem('user'));

  const getData = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${sessionStorage.token}`
      }
    };

    const totalByMes = [];
    const kmByMes = [];
    axios
      .get(`${baseUrl}/dashboard/dashboardPorGrupoEmAberto`, config)
      .then(response => {
        setTotalViagensEmAberto(response.data.totalViagensEmAberto);
        setPercTotalAtendidas(response.data.percentual);
      })
      .catch(error => {
        console.log(`Erro ${error}`);
      })
      .finally(() => {
        setLoadViagemAberto(false);
      });

    axios
      .get(`${baseUrl}/dashboard/dashboardPorGrupo`, config)
      .then(response => {
        setTotalViagens(response.data.totalViagens);
        setPeriodoDias(response.data.periodoDias);
        setTotalPeso(response.data.totalPeso);
        setTotalKM(response.data.totalKM);
        setCarbono(response.data.emissaoCarbono.emissaoCO2biogenico);
        Object.values(response.data.viagens).forEach(val => {
          totalByMes.push(val.totalviagens);
          kmByMes.push(val.totalkm);
        });
        setKmViagensByMes(kmByMes);
      })
      .catch(error => {
        console.log(`Erro ${error}`);
      })
      .finally(() => {
        setLoadKmmMedio(false);
      });

    axios
      .get(
        `${baseUrl}/dashboard/dashboardSituacaoViagens/${usuario.id_cliente}/${dataInicial}/${dataFinal}`,
        config
      )
      .then(response => {
        setSituacaoViagem(response.data.viagens);
        setHistorico(response.data.dadosPeso);
        Object.values(response.data.dadosPeso).forEach(val => {
          kmHistorico.push(val.km);
          pesoHistorico.push(val.peso);
        });
        Object.values(response.data.comparativoSemanal).forEach(val => {
          compSem1.push(val.semana1);
          compSem2.push(val.semana2);
          compSem3.push(val.semana3);
          compSem4.push(val.semana4);
          label.push(val.mes_ano);
        });
      })
      .catch(error => {
        console.log(`Erro ${error}`);
      })
      .finally(() => {
        setLoad(false);
      });
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <Row>
        <Col xxl={6} xl={12}>
          <Row className="g-3">
            <Col xs={12}>
              <GreetingCard usuario={usuario} />
            </Col>
            <Col lg={12}>
              <Row className="g-3">
                <Col md={6}>
                  <PesoMedio
                    peso={parseInt(totalPeso)}
                    viagens={totalViagens}
                    load={loadKmmMedio}
                  />
                </Col>
                <Col md={6}>
                  <ViagensEmAberto
                    fontSize="fs-2"
                    totalViagens={totalViagens}
                    totalViagensEmAberto={totalViagensEmAberto}
                    load={loadViagemAberto}
                  />
                </Col>
                <Col md={6}>
                  <SituacaoViagens
                    data={situacaoViagem}
                    radius={['100%', '80%']}
                    load={load}
                  />
                </Col>
                <Col md={6}>
                  <KmMedioPeriodo
                    data={totalOrder}
                    font="fs-2"
                    totalKM={parseInt(kmByMes[0])}
                    periodoDias={periodoDias}
                    load={loadKmmMedio}
                  />
                </Col>
              </Row>
              <Row className="g-3 mt-1 px-2">
                <Card className="py-3 mb-3">
                  <Card.Body className="py-3">
                    <Row className="g-0">
                      <Col
                        xs={6}
                        md={4}
                        className="border-200 border-bottom border-end pb-4"
                      >
                        <h6 className="pb-1 text-700">Total Viagens</h6>
                        <p className="font-sans-serif text-warning lh-1 mb-1 fs-2">
                          <CountUp end={totalViagens} duration={3} />
                        </p>
                        <div className="d-flex align-items-center">
                          <h6 className="fs--1 text-500 mb-0">
                            últimos {periodoDias} dias
                          </h6>
                        </div>
                      </Col>
                      <Col
                        xs={6}
                        md={4}
                        className="border-200 border-md-200 border-bottom border-md-end pb-4 ps-3"
                      >
                        <h6 className="pb-1 text-700">Peso Total</h6>
                        <p className="font-sans-serif text-warning lh-1 mb-1 fs-2">
                          {totalPeso.toLocaleString('pt-BR', {
                            maximumFractionDigits: 2
                          })}
                          t
                        </p>
                        <div className="d-flex align-items-center">
                          <h6 className="fs--1 text-500 mb-0">
                            últimos {periodoDias} dias
                          </h6>
                        </div>
                      </Col>
                      <Col
                        xs={6}
                        md={4}
                        className="border-200 border-bottom border-end border-md-end-0 pb-4 pt-4 pt-md-0 ps-md-3"
                      >
                        <h6 className="pb-1 text-700">Km Percorrido</h6>
                        <p className="font-sans-serif text-info lh-1 mb-1 fs-2">
                          {totalKM.toLocaleString('pt-BR', {
                            maximumFractionDigits: 2
                          })}
                        </p>
                        <div className="d-flex align-items-center">
                          <h6 className="fs--1 text-500 mb-0">
                            últimos {periodoDias} dias
                          </h6>
                        </div>
                      </Col>
                      <Col
                        xs={6}
                        md={4}
                        className="border-200 border-md-200 border-bottom border-md-bottom-0 border-md-end pt-4 pb-md-0 ps-3 ps-md-0"
                      >
                        <h6 className="pb-1 text-700">Axon CO₂ Less</h6>
                        <p className="font-sans-serif text-info lh-1 mb-1 fs-2">
                          {carbono} t
                        </p>
                        <div className="d-flex align-items-center">
                          <h6 className="fs--1 text-500 mb-0"></h6>
                        </div>
                      </Col>
                      <Col
                        xs={6}
                        md={4}
                        className="border-200 border-md-bottom-0 border-end pt-4 pb-md-0 ps-md-3"
                      >
                        <h6 className="pb-1 text-700">
                          Solicitações Atendidas
                        </h6>
                        <p className="font-sans-serif text-info lh-1 mb-1 fs-2">
                          {percTotalAtendidas} %
                        </p>
                        <div className="d-flex align-items-center">
                          <h6 className="fs--1 text-500 mb-0">
                            últimos {periodoDias} dias
                          </h6>
                        </div>
                      </Col>
                      <Col xs={6} md={4} className="pb-0 pt-4 ps-3">
                        <h6 className="pb-1 text-700">Atendidas</h6>
                        <p className="font-sans-serif text-info lh-1 mb-1 fs-2">
                          {Math.abs(totalViagens - totalViagensEmAberto)}
                        </p>
                        <div className="d-flex align-items-center">
                          <h6 className="fs--1 text-500 mb-0">
                            últimos {periodoDias} dias
                          </h6>
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col xxl={6} xl={12}>
          <Row className="g-3">
            <Col md={12}>
              <PesoKm data={historico} load={load} className="h-100" />
            </Col>
          </Row>
          <Row className="g-3 px-2 mt-1">
            <ComparativoSemanal
              className="mb-3 h-100"
              title="Comparativo Carregamentos - Semanal"
              periodo1={compSem1}
              periodo2={compSem2}
              periodo3={compSem3}
              periodo4={compSem4}
              label={label}
              load={load}
            />
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Viagens;
