import React, { useEffect, useState } from 'react';
import * as echarts from 'echarts/core';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { getColor } from 'helpers/utils';
import { tooltipFormatter } from 'helpers/echart-utils';
import PropTypes from 'prop-types';

const OcorrenciasChartBar = ({ dados }) => {
  const incidentes = dados.ocorrencias.map((i, k) => {
    return i.incidente;
  });
  const data = dados.ocorrencias.map((i, k) => {
    return i.qtde;
  });
  const [tipoColor, setTipoColor] = useState([]);

  const getTipoColors = () => {
    const c = dados.ocorrencias.map((i, k) => {
      return i.controlavel === 1 ? '#27bcfd' : '#f5803e';
    });

    setTipoColor(c);
  };

  useEffect(() => {
    getTipoColors();
  }, []);

  const getOption = () => ({
    tooltip: {
      trigger: 'axis',
      padding: [7, 10],
      backgroundColor: getColor('100'),
      borderColor: getColor('300'),
      textStyle: { color: getColor('dark') },
      borderWidth: 1,
      formatter: tooltipFormatter,
      transitionDuration: 0,
      axisPointer: {
        type: 'none'
      }
    },
    xAxis: {
      type: 'value',
      boundaryGap: '0%',
      axisLine: {
        show: true,
        lineStyle: {
          color: getColor('300')
        }
      },
      axisTick: { show: true },
      axisLabel: {
        color: getColor('500')
      },
      splitLine: {
        show: false
      },
      min: 0
    },
    yAxis: {
      type: 'category',
      data: incidentes,
      boundaryGap: '0%',
      axisLabel: {
        show: true,
        color: getColor('1000'),
        margin: 15,
        width: 180
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: getColor('200')
        }
      },
      axisTick: { show: false },
      axisLine: {
        lineStyle: {
          color: getColor('300')
        }
      }
    },
    series: [
      {
        type: 'bar',
        name: 'Total',
        seriesLayoutBy: 'column',
        data,
        // color: tipoColor,
        lineStyle: { color: getColor('primary') },
        itemStyle: {
          borderRadius: [0, 3, 3, 0],
          color: function (param) {
            return tipoColor[param.dataIndex];
          }
        },

        label: {
          show: true,
          position: 'right',
          color: getColor('600')
        },
        showSymbol: false,
        symbol: 'circle',
        smooth: false,
        emphasis: {
          scale: true
        }
      }
    ],
    grid: { right: '2%', left: '41%', bottom: '15%', top: '7%' }
  });
  return (
    <ReactEChartsCore
      echarts={echarts}
      option={getOption()}
      style={{ height: '18.75rem' }}
    />
  );
};

OcorrenciasChartBar.propTypes = {
  dados: PropTypes.array.isRequired
};

export default OcorrenciasChartBar;
