import React, { useState, useRef, Fragment, useEffect } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
  Button,
  Carousel,
  CarouselItem,
  CarouselControl,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardFooter,
  CardHeader
} from 'reactstrap';
import {
  BsZoomIn,
  BsCloudDownload,
  BsFillArrowRightSquareFill,
  BsFillArrowLeftSquareFill
} from 'react-icons/bs';
import { AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai';

const CarrouselGallery = arr => {
  ////Carroussel and Gallery
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const [base64, setImgBase64] = useState('');
  const [extensao, setImgExtensao] = useState('');
  const [itemModal, setItemModal] = useState('');
  /// Modal
  const [modal, setModal] = useState(false);

  /// toggle = Abre e fecha modal
  const toggle = () => {
    setModal(modal ? false : true);
  };

  const handleDate = data => {
    //Função para formatação da data em DD/MM/YYYY
    const result = data.split(' ')[0].split('-');
    return `${result[2]}/${result[1]}/${result[0]}`;
  };
  /// Carousel => Passa, voltar e ir para img específica (Através da Gallery)
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === arr.arr.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? arr.arr.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = newIndex => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  ///Gallery functions
  const galleryRef = useRef(null);

  const handleLeftClick = e => {
    e.preventDefault();
    galleryRef.current.scrollLeft -= galleryRef.current.offsetWidth;
  };
  const handleRightClick = e => {
    e.preventDefault();
    galleryRef.current.scrollLeft += galleryRef.current.offsetWidth;
  };

  /// Gallery

  const gallery = arr.arr.map((item, index) => {
    return (
      <Card
        id={index}
        style={{
          width: '18rem'
        }}
        key={index}
        className="gallery-card"
      >
        <CardHeader> </CardHeader>
        <img
          onClick={() => {
            setImgBase64(item.imageBase64);
            setImgExtensao(item.extensao);
            setItemModal(item);
            toggle();
          }}
          className="img-cardGallery"
          alt="Sample"
          src={`data:image/${item.extensao};base64,` + item.imageBase64}
          id={index}
        />

        <CardBody
          id={index}
          className="w-100 gallery-card-body d-flex flex-column align-items-center"
          onClick={e => {
            goToIndex(parseInt(e.target.id));
          }}
        >
          <CardTitle
            id={index}
            tag="h5"
            className="cursorPointer d-flex flex-column align-items-center"
          >
            {' '}
            {'#' + item.documento_id}
          </CardTitle>
          <CardSubtitle>{item.descricao}</CardSubtitle>
          <CardFooter className="w-100 py-0 d-flex  align-items-center">
            <a
              // COLOCAR NO ESLINTRC para desativar o err =>'react/no-unknown-property': ['error', { ignore: 'ignore' }]    /// Erro da prop "download" na tag <a>
              className="buttons-download m-1"
              alt="download"
              id={index}
              download={`${item.numero_nf} - ${item.entrega_razao_social} - ${item.documento_id}`}
              href={`data:image/${item.extensao};base64,` + item.imageBase64}
            >
              <BsCloudDownload />
            </a>
            <a
              className="buttons-download m-1"
              onClick={() => {
                setImgBase64(item.imageBase64);
                setImgExtensao(item.extensao);
                setItemModal(item);
                toggle();
              }}
              size="sm"
            >
              <BsZoomIn />
            </a>
          </CardFooter>
        </CardBody>
      </Card>
    );
  });
  ///Retorno do componente
  const ModalGallery = () => {
    return (
      <Modal className="modal-close" isOpen={modal} toggle={toggle}>
        <ModalHeader
          className="w-90 h-100"
          toggle={toggle}
          close={<Button onClick={toggle}>x</Button>}
        >
          {itemModal.documento_id + ' - ' + itemModal.descricao}
        </ModalHeader>

        <img
          src={
            `data:image/${itemModal.extensao};base64,` + itemModal.imageBase64
          }
          className="img-carousel w-100 h-100"
        />
        <ModalFooter />
      </Modal>
    );
  };

  return (
    <Fragment>
      <div className="frag-div">
        <ModalGallery />
        <div className="gallery-cards" ref={galleryRef}>
          {gallery}
        </div>
      </div>
    </Fragment>
  );
};

export default CarrouselGallery;
