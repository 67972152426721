import axios from 'axios';
import CarrouselGallery from 'components/carousel-gallery';
import { baseUrl } from 'config';
import React, { useState, useRef, Fragment, useEffect } from 'react';
import {
  FormGroup,
  Input,
  Label,
  Button,
  Spinner,
  Form,
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText
} from 'reactstrap';
import PageHeader from '../../../components/pages/pageHeader';
import AlertErrorGetData from 'components/alert/errorGetData';

const DocumentosDigitalizados = () => {
  const [loading, setLoading] = useState(false);
  const usuario = JSON.parse(sessionStorage.getItem('user'));
  const [nfCliente, setNfCliente] = useState('');
  const [cliente, setCliente] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [selectClientes, setSelectClientes] = useState([]);

  const [items, setItens] = useState([]);

  const handleDate = data => {
    //Função para formatação da data em DD/MM/YYYY
    const result = data.split(' ')[0].split('-');
    return `${result[2]}/${result[1]}/${result[0]}`;
  };
  const TitleGallery = () => {
    if (items.length > 0) {
      return (
        <>
          <CardTitle
            className="mb-2 cursorPointer d-flex align-items-start"
            tag="h3"
          >
            NF:&nbsp;&nbsp;<b>{'  ' + items[0].numero_nf}</b>
          </CardTitle>
          <CardSubtitle className="text-muted cursorPointer d-flex align-items-start">
            Destino:&nbsp;&nbsp;
            <b>{items[0].entrega_municipio + ' - ' + items[0].entrega_uf}</b>
          </CardSubtitle>
          <CardText className="cursorPointer d-flex flex-column align-items-start">
            <p className="m-0">
              Destinatário:&nbsp;&nbsp;
              <b>{items[0].entrega_razao_social}</b>
            </p>
            <p className="m-0">
              Data:&nbsp;&nbsp;
              <b>{handleDate(items[0].data_emissao)}</b>
            </p>
          </CardText>
        </>
      );
    } else {
      return <></>;
    }
  };
  const getData = async (nfeCliente, cnpj) => {
    const config = {
      headers: {
        Authorization: `Bearer ${sessionStorage.token}`
      }
    };

    await axios
      .get(
        `${baseUrl}/kmm/getDocDigitalizados/${usuario.id_cliente}/${nfeCliente}/${cnpj}`,
        config
      )
      .then(response => {
        setItens(response.data.data);
        if (response.data.data.length === 0) {
          AlertErrorGetData(
            'Não foi encontrado nenhum documento digitalizado para esta NF'
          );
        }
      })
      .catch(error => {
        AlertErrorGetData(error.data.message);
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const getAllClients = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${sessionStorage.token}`
      }
    };

    await axios
      .get(
        `${baseUrl}/auxiliares/getClienteByGrupo/${usuario.id_grupo_cliente}`,
        config
      )
      .then(response => {
        setSelectClientes(response.data);
        console.table(response.data);
      });
  };
  const handleCnpj = str => {
    let string = str;
    let stringLimpa = string.replace(/[./-]/g, '');
    return stringLimpa;
  };
  const SelectGenerator = () => {
    return (
      <Fragment>
        <Input
          style={{ width: '100%' }}
          type="select"
          name="cliente"
          defaultValue={cnpj}
          placeholder="Selecione"
          onChange={e => {
            setCnpj(e.target.value);
            setCliente(e.target.id);
          }}
        >
          <option id="StartValue" value={''}>
            {' '}
          </option>
          {selectClientes.map(cliente => (
            <option
              id={cliente.nome_fantasia}
              key={cliente.id}
              value={handleCnpj(cliente.identificador)}
            >
              {cliente.identificador} - {cliente.nome_fantasia}
            </option>
          ))}
        </Input>
      </Fragment>
    );
  };
  useEffect(() => {
    getAllClients();
    console.table(selectClientes);
  }, []);
  return (
    <Fragment>
      <PageHeader
        title="Consulta de Comprovantes"
        description=" "
        // eslint-disable-next-line
        children={
          <Row className="d-flex align-items-center">
            <Col
              className="d-flex align-items-end"
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
            >
              <FormGroup className="m-3" style={{ width: '10%' }}>
                <Label>Nota Fiscal</Label>

                <Input
                  type="number"
                  name="nf"
                  defaultValue={nfCliente}
                  onChange={e => {
                    setNfCliente(e.target.value);
                  }}
                />
              </FormGroup>
              <FormGroup className="m-3" style={{ width: '80%' }}>
                <Label>Filial</Label>

                <SelectGenerator />
              </FormGroup>

              <Button
                onKeyPress={'Enter'}
                className="m-3 fit-content-height"
                type=""
                onClick={e => {
                  e.key === 'Enter';
                  setLoading(true);
                  getData(nfCliente, cnpj);
                }}
              >
                Buscar
              </Button>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
              <TitleGallery />
            </Col>
          </Row>
        }
      />

      {loading ? (
        <div className="div-loader">
          <Spinner></Spinner>
        </div>
      ) : (
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <CarrouselGallery arr={items} />
          </Col>
        </Row>
      )}
    </Fragment>
  );
};

export default DocumentosDigitalizados;
