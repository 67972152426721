import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.min.css';
import Layout from './layouts/Layout';
import history from './history';
import { AuthProvider } from './hooks/AuthContext';
import { Provider } from 'react-redux';
import store from './store/store';

const App = () => {
  return (
    <Router basename={process.env.PUBLIC_URL} history={history}>
      <AuthProvider>
        <Provider store={store}>
          <Layout />
        </Provider>
      </AuthProvider>
    </Router>
  );
};

export default App;
