import React, { useContext, useEffect, useState } from 'react';
import {
  Accordion,
  Button,
  Card,
  Col,
  Form,
  FormGroup,
  Row,
  Tab,
  Tabs
} from 'react-bootstrap';
import Select from 'react-select';
import DatePicker, { registerLocale } from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './map.css';
import { Label } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { updateFilter } from '../../../../slices/filters';
import { updateConfMapa } from '../../../../slices/confMapa';
import ptBR from 'date-fns/locale/pt-BR';

registerLocale('ptBR', ptBR);

const FiltersMap = props => {
  // eslint-disable-next-line react/prop-types
  const { getMarkers } = props;
  const dispatch = useDispatch();

  const status = [
    { value: 'Destinado', label: 'Destinado' },
    { value: 'Descarga Parcial', label: 'Descarga Parcial' },
    { value: 'Viagem', label: 'Viagem' },
    { value: 'Vazio', label: 'Vazio' },
    { value: 'Descarga', label: 'Descarga' },
    { value: 'Carga', label: 'Carga' }
  ];

  const filters = useSelector(state => state.filters);
  const configMapa = useSelector(state => state.configMapa);

  return (
    <Tabs defaultActiveKey="busca" id="tab-options">
      <Tab eventKey="busca" title="Busca" className="pt-1">
        <Accordion
          id="accordionConfig"
          className="border rounded bg-transparent"
        >
          <Accordion.Item eventKey="0" className="border-0">
            <Card.Header className="p-0" id={`AccordionHeading0`}>
              <Accordion.Button className="btn btn-link text-decoration-none d-block w-100 py-2 px-3 border-0 rounded-0 text-start shadow-none">
                <FontAwesomeIcon
                  icon="caret-right"
                  className="accordion-icon me-3 "
                  transform="shrink-2"
                />
                <span className="fw-medium font-sans-serif text-900">
                  Filtro
                </span>
              </Accordion.Button>
            </Card.Header>
            <Accordion.Body className="p-0">
              <Card.Body>
                <Row>
                  <Col sm={12} md={12} lg={6} xl={6}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Placa</Form.Label>
                      <Form.Control
                        type="placa"
                        onChange={e => {
                          dispatch(
                            updateFilter({
                              label: 'placa',
                              value: e.target.value
                            })
                          );
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={12} md={12} lg={6} xl={6}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Destinatário</Form.Label>
                      <Form.Control
                        type="origem"
                        onChange={e => {
                          dispatch(
                            updateFilter({
                              label: 'origem',
                              value: e.target.value
                            })
                          );
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={12} md={12} lg={12} xl={12}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Status</Form.Label>
                      <Select
                        options={status}
                        placeholder={'Selecione'}
                        isMulti
                        closeMenuOnSelect={true}
                        onChange={value => {
                          updateFilter();
                        }}
                        theme={theme => ({
                          ...theme,
                          borderRadius: 4,
                          colors: {
                            ...theme.colors,
                            primary25: 'hotpink',
                            primary: 'black',
                            neutral0: '#0b1727',
                            neutral20: '#344050',
                            neutral10: '#d3dfee'
                          }
                        })}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6} md={6} lg={6} xl={6}>
                    <Form.Group>
                      <Form.Label>Data inicial</Form.Label>
                      <DatePicker
                        value={filters.filters.dataInicial}
                        locale={ptBR}
                        dateFormat="dd/MM/Y"
                        onChange={e => {
                          dispatch(
                            updateFilter({
                              label: 'dataInicial',
                              value: e.toISOString()
                            })
                          );
                        }}
                        className="form-control"
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={6} md={6} lg={6} xl={6}>
                    <Form.Group>
                      <Form.Label>Data final</Form.Label>
                      <DatePicker
                        value={filters.filters.dataFinal}
                        locale={ptBR}
                        dateFormat="dd/MM/yyyy"
                        onChange={e => {
                          dispatch(
                            updateFilter({
                              label: 'dataFinal',
                              value: e.toISOString()
                            })
                          );
                        }}
                        className="form-control"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6} md={6} lg={3} xl={3} className="pt-3">
                    <Button
                      className="btn btn-outline-light"
                      onClick={() => {
                        getMarkers();
                      }}
                    >
                      Buscar
                    </Button>
                  </Col>
                </Row>
              </Card.Body>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Tab>
      <Tab eventKey="configuracoes" title="Configurações" className="p-3">
        <Row>
          <Col>
            <FormGroup>
              <Label>Visualização dinâmica</Label>
              <Form.Check
                type="switch"
                id="visualizacao_dinamica"
                label="Sim, navegar automaticamente"
                defaultChecked={configMapa.conf.visualizacaoDinamica}
                onChange={() => {
                  dispatch(
                    updateConfMapa({
                      label: 'visualizacaoDinamica',
                      value: !configMapa.conf.visualizacaoDinamica
                    })
                  );
                }}
              />
            </FormGroup>
          </Col>
          <Col>
            <p>Tipo de mapa</p>
            <Button
              onClick={() => {
                dispatch(
                  updateConfMapa({
                    label: 'tipo',
                    value: 'mapbox://styles/axonapi/cldnuql8u006d01o02w9bxz4h'
                  })
                );
              }}
            >
              Satélite
            </Button>
            <Button
              onClick={() => {
                dispatch(
                  updateConfMapa({
                    label: 'tipo',
                    value: 'mapbox://styles/axonapi/clegd7xv2000101o8azdwlqn2'
                  })
                );
              }}
            >
              Simples
            </Button>
          </Col>
        </Row>
      </Tab>
    </Tabs>
  );
};

export default FiltersMap;
