import React from 'react';
import processList from 'data/feature/processList';
import Section from 'components/common/Section';
import Process from './Process';
import SectionHeader from './SectionHeader';
import { isIterableArray } from 'helpers/utils';

const Processes = () => (
  <Section>
    <SectionHeader
      title="Um mundo conectado, um mundo de dados."
      subtitle="O ramo de transporte e logística está cada vez mais dinâmico e a Axon está preparada para atender as necessidades de sua empresa."
    />
  </Section>
);

export default Processes;
