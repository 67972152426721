import { matchRoutes, useLocation } from 'react-router-dom';

export const useCurrentPath = () => {
  const location = useLocation();
  return location;
};

export const dateTimeEnToBr = dateString => {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.getMonth() + 1; // Os meses em JavaScript começam a partir do zero
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();

  // Adicione um zero à esquerda se o dia ou mês tiverem apenas um dígito
  const formattedDay = day < 10 ? `0${day}` : day;
  const formattedMonth = month < 10 ? `0${month}` : month;

  return `${formattedDay}/${formattedMonth}/${year} ${hours}:${minutes}`;
};

export const getSubdomain = () => {
  const { hostname } = window.location;
  const regex = /^(.*?)\./;
  const match = hostname.match(regex);
  if (match && match.length > 1) {
    return match[1];
  }
  return null; // Não há subdomínio
};

export const arredondarParaCima = velocidade => {
  if (isNaN(velocidade)) {
    throw new Error('O valor fornecido não é um número válido.');
  }
  return Math.ceil(Number(velocidade));
};
